import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./profile.scss";

import {
  getProfileData,
  getEditData,
  updateProfileCheck,
} from "../../app/actions/Profile";
import { getCities, setUpdatedLanguage, } from "../../app/actions/Global";
import { Link, useNavigate } from "react-router-dom";

import ProfileBio from "./bio";
import ProfileAttrribute from "./attributes";

import Slider from "react-slick";
//
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Likers from "./likers";
import Viewers from "./viewers";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import MainImagePlaceholder from "../../assets/images/Main/user-placeholder.jpg";
import { showAlert } from "../../components/showAlerts";

import { checkViewSize } from "../../app/utilities/profile-script";
import tickImage from '../../assets/images/tick2.png';
import imagePlacholder from "../../assets/images/Main/user-placeholder.jpg";
/*  */
//
// import axios from "axios";

import { useTranslation } from "react-i18next";
import { capitalizeWords } from "../../app/utilities/functions";

import {
  getLanguageFromLocalStorage,
  savePageTranslateLocalStorage,
  initializeI18n,
  loadCities,
} from "../../app/utilities/localization";

import { Tooltip } from "react-tooltip";
import AxiosWithAuth from "../../app/auth/jwtMiddleware";

//

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import canvasPreview from "./canvasPreview";

import { useDebounceEffect } from "./useDebounceEffect";

import ImageCropper from "../../components/ImageCropper";


const url = process.env.REACT_APP_PUBLIC_URL;

//

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 50,
        height: 50,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = getLanguageFromLocalStorage() || "en";
  const inputElement = useRef();
  const [isMobile, setIsMobile] = useState(true);
  const dispatch = useDispatch();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [isBrowser, setIsBrowser] = useState(true);



  //
  const [dta, setDta] = useState([]);
  const [liker, setLiker] = useState([]);
  const [dtaviwer, setVwdta] = useState([]);
  const [viewer, setViewer] = useState([]);

  //
  //
  const [bioForm, setBioForm] = useState();
  const [selectedcountry, setselectedcountry] = useState();
  const [selectedcity, setselectedcity] = useState();
  // var  = new FormData();

  // const [city]

  //

  const [imgSrc, setImgSrc] = useState("");
  const [imgSrcOrignal, setImgSrcOrignal] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1);

  //

  const {
    profileData,

    profileattributesComplete,
    profileAttributes,
    profileCheck,
    isProfileLoading,
    editData,
    isEditDataLoading
  } = useSelector((state) => state.profile);




  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      // console.log(width)
      // console.log(height)
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const handleImageError = (e) => {
    // Replace the failed image with a placeholder image
    e.target.src = imagePlacholder; // Replace with the path to your placeholder image
  };

  // console.log(profileData)
  const { citiesData,isCitiesLoading} = useSelector((state) => state.global);

  //
  const [cropimage, setCropImage] = useState("");
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const [loading, setLoading] = useState(false);
  const [intiLoading, setInitLoading] = useState(true);

  //
  const [selectCity, selectedCity] = useState(
    profileData?.profile?.location?.city?.slug
  );
  const [selectCountry, selectedCountry] = useState(
    profileData?.profile?.location?.country?.code
  );
  const [cities, setCities] = useState(citiesData);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
    const fetchData = async () => {
      await savePageTranslateLocalStorage("profile");

      initializeI18n([
        "religion",
        "language",
        "education",
        "career",
        "FamilyType",
        "familyValue",
        "maritalStatus",
        "diet",
        "children",
        "caste",
        "hobby",
        "countries",
        "premium",
      ]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setInitLoading(false);
    }, 300);
  }, [isProfileLoading]);

  const viewerslidersettings = {
    dots: false,
    arrows: false,
    slidesToShow: 4,
    infinite: false,
  };

  const likersslidersettings = {
    dots: false,
    arrows: false,
    slidesToShow: 4,
    infinite: false,
  };

  const profileGallerySliderSettings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    infinite: false,
  };

  // // const [index, setIndex] = useState(0)



  useEffect(() => {
    dispatch(updateProfileCheck(false));
    selectedCity(profileData?.profile?.location?.city?.slug);
    selectedCountry(profileData?.profile?.location?.country?.code);

    if (profileData?.profile?.location?.country?.code) {
      // console.log('copopuntry code');
      // console.log(profileData?.profile?.location?.country?.code);
      loadCities(profileData?.profile?.location?.country?.code);
       if(!isCitiesLoading && citiesData.length===0 ){
      // dispatch(getCities(profileData?.profile?.location?.country?.code));
       }
    }


    // console.log(editData?.length)
    // console.log(isEditDataLoading)

    // dispatch(getEditData());

      // setToggleEdit(true);

  if(!profileData?.profile?.height?.full || !profileData?.profile?.family_value?.name || !profileData?.profile?.family_type?.name){

    setToggleEdit(true);

    loadCities(profileData?.profile?.location?.country?.code);
    if(!isCitiesLoading && citiesData.length===0 ){
      dispatch(getCities(profileData?.profile?.location?.country?.code));
        }


  }
  }, [profileData]);

  useEffect(() => {
    setCities(citiesData?.data);
    // console.log(citiesData?.data)

    if (citiesData?.data?.length > 0) {
      var setCity = citiesData?.data.filter((cty) => {
        return cty.slug == selectCity;
      });
      // console.log(setCity)
      if (setCity?.length > 0) {
        selectedCity(setCity[0]?.slug);
        setselectedcity(setCity[0]?.slug);
      } else {
        selectedCity(citiesData?.data[0]?.slug);
        setselectedcity(citiesData?.data[0]?.slug);
      }
    }
    // setSelectedCities(citiesData?.data[0]?.slug);
  }, [citiesData,editData]);

  useEffect(() => {
    //  console.log(editData?.data?.country)
    // setCountries(edit)
  }, [editData]);

  useEffect(() => {
    // Function to check if the window width indicates a mobile view
    const checkMobile = () => {
      // console.log(window.innerWidth);
      setIsMobile(window.innerWidth <= 767);
    };

    // Initial check

    // console.log("check view");
    // console.log(isMobile);
    checkViewSize();
    setIsMobile(window.innerWidth <= 767);

    // Event listener for window resize
    const handleResize = () => {
      checkMobile();
      checkViewSize();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup: remove the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Th

  // useEffect(() => {
  //   checkViewSize();
  //   // Che
  //   //

  //   chec

  //   console.log('hehe')

  //   //

  function editToggle(e) {
    e.preventDefault();
 
    if(editData?.length===0  && !isEditDataLoading){

    dispatch(getEditData());

    }
    setToggleEdit(!toggleEdit);

    
     if(!isCitiesLoading && citiesData.length===0 ){
      dispatch(getCities(profileData?.profile?.location?.country?.code));
        }

  }


  // editData

  function closeToggle() {

    setToggleEdit(!toggleEdit);
  }

  function showViewUsersPopup(e) {
    e.preventDefault();
    document.getElementById("views-popup").classList.add("active");
    loadMoreViewerprofiles();
  }

  function showLikeUsersPopup(e) {
    e.preventDefault();
    document.getElementById("likes-popup").classList.add("active");
    loadMoreLikerProfiles();
  }

  var pageLikenumber = 0;
  const pageLikeLimit = 20;
  var loadingLikersProfiles = false;

  // likers useEffect

  useEffect(() => {
    setLiker(dta);
  }, [dta]);

  useEffect(() => {
    setViewer(dtaviwer);
  }, [dtaviwer]);

  //
  function loadMoreLikerProfiles() {
    if (!loadingLikersProfiles) {
      loadingLikersProfiles = true;
      var formData = new FormData();
      formData.append("user", profileData?.main?.id);
      formData.append("limit", pageLikeLimit);
      formData.append("page", pageLikenumber);
      formData.append("language", language);
      pageLikenumber = pageLikenumber + 1;
      setLoading(true);
      try {
        AxiosWithAuth()
          .post(`${url}/users/get-likers`, formData)
          .then(({ data }) => {
            if (data?.status === 200) {
              // console.log(data);
              setDta(data?.data);
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error?.code === "ERR_NETWORK") {
              showAlert(error?.message);
            }
            if (error?.response?.data?.status === 422) {
              showAlert(error?.response?.data?.data);
            }
            // console.log(error);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  }

  var loadingviewersProfiles = false;
  var pageViewnumber = 0;
  const pageviewLimit = 20;

  function loadMoreViewerprofiles() {
    if (!loadingviewersProfiles) {
      loadingviewersProfiles = true;
      var formData = new FormData();
      formData.append("user", profileData?.main?.id);
      formData.append("limit", pageviewLimit);
      formData.append("page", pageViewnumber);
      formData.append("language", language);
      pageViewnumber = pageViewnumber + 1;
      setLoading(true);
      try {
        AxiosWithAuth()
          .post(`${url}/users/get-viewers`, formData)
          .then(({ data }) => {
            if (data?.status === 200) {
              setVwdta(data?.data);
              setLoading(false);
              // console.log(data?.data)
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error?.code === "ERR_NETWORK") {
              showAlert(error?.message);
            }
            if (error?.response?.data?.status === 422) {
              showAlert(error?.response?.data?.data);
            }
          });
      } catch (error) {
        setLoading(false);
      }
    }
  }

  function closePopupViewers() {
    document.getElementById("views-popup").classList.remove("active");
  }

  function closePopupLikers() {
    document.getElementById("likes-popup").classList.remove("active");
    setDta([]);
  }
  // console.log(profileData)

  const imageSelect = (e) => { 
    // console.log(e.target.files);
    var files = e.target.files;
   
    
    var reader;
    var file;
    var url;
    if (
      navigator.userAgent.includes("FB_IAB") ||
        navigator.userAgent.includes("FB4A") ||
        navigator.userAgent.includes("FBAV") ||
        navigator.userAgent.includes("Instagram") ||
        navigator.userAgent.includes("instagram") ||
        navigator.userAgent.includes("Android") 
    ) {
      setIsBrowser(false);

    }


    //

    if (files && files.length > 0) {
    
      if (files[0].size > 10 * 1024 * 1024) {
        showAlert("Please select an image less than 10MB.");
        return; 
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImgSrc(reader.result);
        setImgSrcOrignal(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    var done = function (url) {
      //  image.src = url;
      // console.log(url);
      // setImgSrc(url)
      setCropImage(url);
      setCrop(url);
      //console.log(url);
      //   // $modal.modal('show');



      document.getElementById("cropModal").classList.add("active");
    };

    if (files && files.length > 0) {
      file = files[0];

      if (URL) {
        done(URL.createObjectURL(file));
      } else if (FileReader) {
        reader = new FileReader();
        reader.onload = function (e) {
          done(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // const cropImage = () => {
  //   if (completedCrop && imgSrc) {
  //     const image = new Image();
  //     image.src = imgSrc;

  //     const canvas = previewCanvasRef.current;
  //     const scaleX = image.naturalWidth / image.width;
  //     const scaleY = image.naturalHeight / image.height;

  //     canvas.width = completedCrop.width;
  //     canvas.height = completedCrop.height;

  //     const ctx = canvas.getContext('2d');

  //     ctx.drawImage(
  //       image,
  //       completedCrop.x * scaleX,
  //       completedCrop.y * scaleY,
  //       completedCrop.width * scaleX,
  //       completedCrop.height * scaleY,
  //       0,
  //       0,
  //       completedCrop.width,
  //       completedCrop.height
  //     );

  //     // const blob = canvas.toBlob('image/png');
  //     const dataURL  = canvas.toDataURL('image/png');
  //     // console.log(blob)
  //     if(dataURL!== "#" || dataURL !== ""){
  //       uploadImage(dataURL);
  //     }

  //   }
  //   else if(imgSrc){

  //   }

  // };

  const handleComplete = (crop) => {
    setCompletedCrop(crop);
  };

  const cropImage = () => {
    var imagedata = "";

    if (typeof cropper !== "undefined") {
      // console.log(cropper.getCroppedCanvas().toDataURL());
      imagedata = cropper
        .getCroppedCanvas({
          width: 600,
          height: 600,
          // Limit max sizes
          maxWidth: 2000,
          maxHeight: 2000,
        })
        .toDataURL();
      setCropData(
        cropper
          .getCroppedCanvas({
            width: 600,
            height: 600,
            // Limit max sizes
            maxWidth: 2000,
            maxHeight: 2000,
          })
          .toDataURL()
      );
    }

    // console.log("This is the Croped Image", imagedata);
    if (imagedata !== "#" || imagedata !== "") {
      uploadImage(imagedata);
    }
  };

  function uploadImage(cropdata) {
    var image = {
      image: cropdata,
      original_photo: imgSrcOrignal,
      user_id: profileData?.main?.id,
    };
    var headers = {
      "Content-Type": "multipart/form-data" 
    };
    setLoading(true);
    try {
      AxiosWithAuth()
        .post(`${url}/profiles/update-photo`, image,headers)
        .then((data) => {
          // console.log(data);
          setLoading(false);
          if (data?.status === 200) {
            showAlert(data?.data?.message);
            var formData = new FormData();
            formData.append("slug", profileData?.profile?.slug);

            formData.append("language", language);

            dispatch(getProfileData(formData));
          } else {

          }

          setImgSrc(null);
          setCrop({ aspect: 1 / 1 });
          setCompletedCrop(null);

          //
          document.getElementById("cropModal").classList.remove("active");
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          }
        });
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  }

  function uploadImageDirect(e) {
    e.preventDefault();
    if (!e.target.files) {
      return false;
    }
    // console.log(e.target.file);
    // console.log(e.target.files[0]);

    var fd = new FormData();
    fd.append("photo", e.target.files[0]);
    fd.append("user_id", profileData?.main?.id);

    setLoading(true);

    var headers = {
      contentType: false,
      processData: false,
    };

    try {
      AxiosWithAuth()
        .post(`${url}/profiles/update-photo-direct`, fd, headers)
        .then((data) => {
          // console.log(data);
          setLoading(false);

          if (data?.status === 200) {
            showAlert(data?.data?.message);
            var formData = new FormData();
            formData.append("slug", profileData?.profile?.slug);

            formData.append("language", language);

            dispatch(getProfileData(formData));
          } else {
            // console.log("error");
          }

          setImgSrc(null);
          setCrop({ aspect: 1 / 1 });
          setCompletedCrop(null);
          document.getElementById("directPhoto").value("");
          //
          document.getElementById("cropModal").classList.remove("active");
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  }

  const handleCloseCropper = (e) => {
    e.preventDefault();

    setImgSrc(null);
    setCrop({ aspect: 1 / 1 });
    setCompletedCrop(null);
    document.getElementById("cropModal").classList.remove("active");
  };

  function setPrimary(e, image) {
    // console.log(data);
    var imagedata = {
      id: image?.id,
    };
    setLoading(true);
    try {
      AxiosWithAuth()
        .post(`${url}/profiles/set-primary-photo`, imagedata)
        .then((data) => {
          setLoading(false);

          if (data?.status === 200) {
            showAlert(data?.data?.message);
            var formData = new FormData();
            formData.append("slug", profileData?.profile?.slug);
            formData.append("language", language);
            dispatch(getProfileData(formData));
          } else {
            // console.log("set primary error");
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          }
        });
    } catch (error) {
      setLoading(false);
      // console.log(e);
    }
  }

  function deleteImage(e, image) {
    var imagedata = {
      id: image?.id,
    };
    setLoading(true);
    try {
      AxiosWithAuth()
        .post(`${url}/profiles/delete-photo`, imagedata)
        .then((data) => {
          setLoading(false);
          if (data?.status === 200) {
            var formData = new FormData();
            formData.append("slug", profileData?.profile?.slug);
            formData.append("language", language);
            dispatch(getProfileData(formData));
          } else {
            console.log("delete error");
          }
        })
        .catch((error) => {
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          }
          setLoading(false);
        });
    } catch (error) {
      console.log("delete error catch");
      setLoading(false);
      console.log(error);
    }
  }



  const handleCountry = (e) => {
    // console.log(e);
    // profileForm.append("country", e);
    loadCities(e);
    selectedCountry(e);
    setselectedcountry(e);
    dispatch(getCities(e));
  };

  const handleCity = (e) => {
    selectedCity(e);
    setselectedcity(e);
    // profileForm.append("city", e);
  };

  //
  //
  //

  const handleAttributeSelect = (e) => {
    e.preventDefault();
    // console.log(e);
    // setAttributesForm(value)
    // console.log(value);
    // setAttributesForm(value);
    //   for (var pair of formDataPrecios.entries()) {
    //     formData.append(pair[0], pair[1]);
    // }
  };

  const handleTextChanged = (value) => {
    // console.log(value);
    setBioForm(value);
    // setBioForm(value)
  };

  function saveProfile() {
    var sendData = new FormData();
    sendData.append("id", profileData?.main?.id);
    sendData.append("language", language);
    if (bioForm) {
      sendData.append("bio", bioForm?.bio);
      sendData.append("search_bio", bioForm?.search_bio);
    }
    // sendData.append("search_bio", bioForm?.search_bio);
    // console.log(attributesForm)
    if (profileAttributes) {
      profileAttributes.forEach((item) => {
        for (let key in item) {
          if (item.hasOwnProperty(key)) {
            // console.log("key");
            // console.log(item[key])
            sendData.append(key, item[key]);
          }
        }
      });
    }

    // if (attributesForm) {
    //   attributesForm.forEach((item) => {
    //     for (let key in item) {
    //       if (item.hasOwnProperty(key)) {
    //         sendData.append(key, item[key]);
    //       }
    //     }
    //   });
    // }
    if (selectedcountry) {
      sendData.append("country", selectedcountry);
    }
    if (selectedcity) {
      sendData.append("city", selectedcity);
    }
    // sendData.append(profileAttributes)

    if (!bioForm?.bio || !bioForm?.search_bio || !profileattributesComplete) {
      // console.log("here");
      return;
    }

    updateProfileCheck(false);
    setLoading(true);
    try {
      AxiosWithAuth()
        .post(`${url}/profiles/update`, sendData)
        .then((data) => {
          // console.log(data?.data?.data?.redirect);
          setLoading(false);
          if (data?.status === 200) {
            if (data?.data?.data?.redirect === true) {
              navigate("/premium");
            } else {
              var formData = new FormData();
              formData.append("slug", profileData?.profile?.slug);
              formData.append("language", language);
              // console.log('pr')
              // dispatch(getProfileData(formData));
              showAlert(data?.data?.message);
              navigate("/profile");
              closeToggle();
            }
            // editToggle()
          } else {
            showAlert(data?.data?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          }
        });
    } catch (error) {
      setLoading(false);
    }

    // saveProfile
  }

  
  function uploadIdVeriDirect(e) {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) {
      return false;
    }
    var fd = new FormData();
    fd.append("idphoto", e.target.files[0]);
    fd.append("user_id", profileData?.main?.id);

    setLoading(true);

    var headers = {
      contentType: false,
      processData: false,
    };

    try {
      AxiosWithAuth()
        .post(`${url}/profiles/update-idphoto-direct`, fd, headers)
        .then((data) => {
          // console.log(data);
          setLoading(false);

          if (data?.status === 200) {
            showAlert(data?.data?.message);
            var formData = new FormData();
            formData.append("slug", profileData?.profile?.slug);

            formData.append("language", language);

            dispatch(getProfileData(formData));
          } else {
            // console.log("error");
          }

          setImgSrc(null);
          setCrop({ aspect: 1 / 1 });
          setCompletedCrop(null);
          document.getElementById("directPhoto").value("");
          //
          document.getElementById("cropModal").classList.remove("active");
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          }
        });
    } catch (error) {
      setLoading(false);
    }

  }

  function upgradeSubscription(e) {
    e.preventDefault();
    navigate("/premium");
  }

  function saveProfileIncomplete(e) {
    e.preventDefault();

    dispatch(updateProfileCheck(true));
    showAlert(t("error-fields"));
  }

  useDebounceEffect(
    async () => {
      // console.log(completedCrop)
      // console.log(scale)
      // console.log(rotate)
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  //

  return (
    <>
      {intiLoading || isEditDataLoading ? (
        <>
          {" "}
          <div className="loader profile-load"></div>
        </>
      ) : (
        <></>
      )}

      {loading || isProfileLoading || isEditDataLoading? <div className="loader"></div> : <></>}
      {/*  */}

      <div id="Profile" className="">
        <div id="cropModal" className="popup-main">
          <div className="wrapper">
            {/* <button

              type="button"
              className="main-bt mt-20"
              id="crop"
              onClick={cropImage}
            >
          {t(['save',''])}
            </button> */}

            <button
              className="close"
              onClick={(e) => handleCloseCropper(e)}
            ></button>

            <div className="cropwrapper">
              {/* <div className="image-cropper"> */}
              {/* 
            <ReactCrop
          crop={crop}
          onChange={setCrop}
          onComplete={handleComplete}
          aspect={aspect}
          minWidth={50}
          maxHeight={1000}
          // className="preview"
          
        >
          <img
           ref={imgRef}
            // ref={crop}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg) ` }}
            onLoad={onImageLoad}
            // className="preview"
          />

          <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                // width: completedCrop.width,
                // height: completedCrop.height,
              }}
            /> */}
              {/* </ReactCrop> */}
              {!isBrowser ? (
                <>
                  <ImageCropper 
                  imgSrc ={imgSrc}
                  uploadImage ={uploadImage}
                  registrationCropper = {false}
                  />
                </>
              ) : (
                <>                
                <Cropper
                zoomable={false}
                initialAspectRatio={1}
                scaleX={1}
                scaleY={1}
                aspectRatio={1 / 1}
                preview=".preview"
                src={cropimage}
                viewMode={3}
                minCropBoxWidth={100}
                checkOrientation={false}
                guides={true}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />
              <button
                type="button"
                className="main-bt mt-20"
                id="crop"
                onClick={cropImage}
              >
                {t(["save", ""])}
              </button>
              </>

              )}


              {/* </div> */}

            </div>
          </div>
        </div>

        {/*  */}

        <div id="likes-popup" className="popup-main" onClick={closePopupLikers}>
          <div className="wrap">
            <button className="close" onClick={closePopupLikers}></button>
            <span className="heading">{t(["liker-popup", ""])}</span>
            <div className="user-feeds" id="allLikers">
              {liker?.length > 0 ? (
                <>
                  <Likers data={liker} />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        {/* <div
          id="views-popup"
          className="popup-main"
          onClick={closePopupViewers}
        >
          <div className="wrap">
            <button className="close" onClick={closePopupViewers}></button>
            <span className="heading">{t(["viewer-popup", ""])}</span>

            <div className="user-feeds" id="allViewers">
              {viewer?.length > 0 ? (
                <>
                  <Viewers data={viewer} />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div> */}

        <div id="profile-page" className={toggleEdit ? "editmode" : ""}>
          <div className="section left">
            <div className="left" id="mobile-left-bar">
              <div id="primary-gallery">
                <div
                  className={`column ${
                    profileData?.is_premium ? "is_premium_user" : ""
                  }`}
                  id="photo-1"
                >
                  <div className="photo">
                    {/*  */}
                    {profileData ? (
                      // console.log(profileData),
                      // photo edited
                      <>
                        {profileData?.photos?.length > 0 ? (
                          <>
                            {Object.entries(profileData?.photos).map(
                              ([id, photo]) => (
                                <>
                                  {photo?.is_primary === 1 ? (
                                    <>
                                      <img
                                        key={id}
                                        src={
                                          photo?.url
                                            ? photo?.url
                                            : imagePlacholder
                                        }
                                        alt={`${profileData?.profile?.last_name} +"maim"`}
                                        onError={handleImageError}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            <img
                              key={"key phpto"}
                              src={MainImagePlaceholder}
                              alt={`${profileData?.profile?.last_name}-jsdhsjdh `}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <img
                          key={"main-image"}
                          src={MainImagePlaceholder}
                          alt={`${profileData?.profile?.last_name}-jsdfdfdf4545dhsjdh `}
                        />
                      </>
                    )}

                    <div className="edit-element">
                      {/* <div className="delete-photo"></div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div id="user-name-tag-mobile-wrap"></div>
              <ProfileAttrribute
                dataValue={[profileData, editData, toggleEdit]}
                onSelect={(e) => handleAttributeSelect(e)}
              />


            </div>

          {/* mk id photo start*/}
          <div >
  {/* Assuming idphoto is available in profileData */}

  <div className="column a mk_id_phonto" >
 <span className="heading mt-30 small">Id Verification Document</span>

      <div className="photo">

      <div className="edit-element">
      {(profileData?.id_veristatus === 2  || !profileData?.idphoto) ?(
         <input
                             type="file"
                             name="id_verification"
                             className={`form-control profile-photo`}
                             accept="image/*"
                             id="profile-photo"
                             onChange={uploadIdVeriDirect}
                           /> ): null} 
          
         </div>

        {profileData?.idphoto?.path && profileData?.idphoto?.filename ? (
        <img 
        style={{ width: "100%" }}
        src={
        profileData?.idphoto?.filename
        ? `https://cdn.mohabbatein.com/${profileData?.idphoto?.path}${profileData?.idphoto?.filename}` // Construct the correct path
        : ""
        }
        alt={
        profileData?.profile?.first_name + " " + profileData?.profile?.last_name
        } // Keep the alt text simple and descriptive
        // onError={handleImageError}
        />
        ): null}

       
      </div>
    </div>

</div>


          {/* mk id photo end*/}
          </div>

          <div className="right section" id="mid-section">
            <div className="top" id="top-main">
              <div className="details" id="user-name-tag">
                <div className="main">
                  <div className="name" id="profile-name">
                    {typeof profileData?.profile?.first_name !== "undefined" &&
                      capitalizeWords(profileData?.profile?.first_name)}{" "}
                    {typeof profileData?.profile?.last_name !== "undefined" &&
                      capitalizeWords(profileData?.profile?.last_name)}
 <p>
      <span  >{profileData?.main?.id_veri_status === 1 && (
      <img src={tickImage} alt="Tick"  style={{height:'24px'}} />
      )}</span>
      


                    <span className="age">{profileData?.main?.age}</span> </p>
                  </div>

                  <div id="location-tag-wrap">
                    <span className="attribute location" id="location-tag">
                      <label>{t(["residing-location-lable", ""])}</label>
                      <div className="setdata" id="profile-location">
                        {profileData?.profile?.location?.city?.slug &&
                        profileData?.profile?.location?.city?.name
                          ? t([
                              profileData?.profile?.location?.city?.slug,
                              profileData?.profile?.location?.city?.name,
                            ])
                          : profileData?.profile?.location?.city?.name}
                        ,{" "}
                        {profileData?.profile?.location?.country?.code &&
                        profileData?.profile?.location?.country?.name
                          ? t([
                              profileData?.profile?.location?.country?.code,
                              profileData?.profile?.location?.country?.name,
                            ])
                          : profileData?.profile?.location?.country?.name}
                      </div>

                      <div className="edit-field loc">
                        <div className="autocomplete">
                          <select
                            id="edit-country"
                            name="country"
                            placeholder="Country"
                            value={selectCountry}
                            onChange={(e) => handleCountry(e.target.value)}
                          >
                            {editData?.data ? (
                              //  console.log(editData?.data),
                              // edit Data
                              // console.log(editData),
                              <>
                                {Object.entries(editData?.data?.country).map(
                                  ([id, country]) => (
                                    // console.log(country),
                                    <option
                                      key={"country-" + id}
                                      value={country?.code}
                                    >
                                      {country?.code && country?.name
                                        ? t([country?.code, country?.name])
                                        : country?.name}
                                    </option>
                                  )
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                        <div className="autocomplete">
                          <select
                            id="edit-city"
                            name="city"
                            placeholder="City"
                            value={selectCity}
                            onChange={(e) => handleCity(e.target.value)}
                          >
                            {cities ? (
                              //  console.log(cities),
                              <>
                                {Object.entries(cities).map(([id, city]) => (
                                  <option key={"city-" + id} value={city.slug}>
                                    {/* {t([city.slug, city.name])} */}
                                    {city?.slug && city?.name
                                      ? t([city?.slug, city?.name])
                                      : city?.name}
                                  </option>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div id="self-tab" onClick={(e) => editToggle(e)}>
                <a href="/" id="editProfile">
                  {t(["edit-profile", ""])}
                </a>
              </div>
            </div>

            <div className="bottom">
              <ProfileBio
                dataValue={profileData}
                textChanged={handleTextChanged}
              />
            </div>
            <div className="right">
              <div id="user-photos-wrap mk_profile">
                <div id="user-photos">
                  {/*  */}

                  {profileData ? (
                    // console.log(profileData),
                    <>
                      {profileData?.photos?.length > 0 ? (
                        <>
                          {/* profiele data */}
                          {isMobile ? (
                            <>
                              <div id="profile-gallery">
                                <Slider
                                  className="slick-dotted"
                                  {...profileGallerySliderSettings}
                                >
                                  {Object.entries(profileData?.photos).map(
                                    ([id, photo]) => (
                                      //<>
                                      <>
                                        {photo?.is_primary === 0 ? (
                                          <div
                                            key={id + "-" + photo?.url}
                                            className={`column herewq`}
                                          >
                                            <div className={`photo`}>
                                              <img
                                                src={
                                                  photo?.url
                                                    ? photo?.url
                                                    : imagePlacholder
                                                }
                                                alt={
                                                  profileData?.profile?.first_name +
                                                  " " +
                                                  profileData?.profile?.last_name
                                                }
                                                onError={handleImageError}
                                              />
                                              <div className="edit-element">
                                                <div
                                                  className="delete-photo"
                                                  onClick={(e) =>
                                                    deleteImage(e, photo)
                                                  }
                                                ></div>
                                                <div
                                                  className="set-primary"
                                                  onClick={(e) =>
                                                    setPrimary(e, photo)
                                                  }
                                                >
                                                  {t(["set-primary", ""])}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            <div
                                              key={id + "-" + photo?.url}
                                              className={`column ${
                                                profileData?.is_premium
                                                  ? "is_premium_user"
                                                  : ""
                                              }`}
                                            >
                                              <div className={`photo`}>
                                                <img
                                                  src={
                                                    photo?.url
                                                      ? photo?.url
                                                      : imagePlacholder
                                                  }
                                                  alt={
                                                    profileData?.profile?.first_name +
                                                    " " +
                                                    profileData?.profile?.last_name
                                                  }
                                                  onError={handleImageError}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )
                                  )}
                                </Slider>
                              </div>
                            </>
                          ) : (
                            <>
                              <div id="profile-gallery">
                                {Object.entries(profileData?.photos).map(
                                  ([id, photo]) => (
                                    //<>
                                    <>
                                      {photo?.is_primary === 0 ? (
                                        <div
                                          key={id + "-" + photo?.url}
                                          className="column a"
                                        >
                                          <div className={`photo`}>
                                            <img
                                              src={
                                                photo?.url
                                                  ? photo?.url
                                                  : imagePlacholder
                                              }
                                              alt={
                                                profileData?.profile?.first_name +
                                                " " +
                                                profileData?.profile?.last_name
                                              }
                                              onError={handleImageError}
                                            />
                                            <div className="edit-element">
                                              <div
                                                className="delete-photo"
                                                onClick={(e) =>
                                                  deleteImage(e, photo)
                                                }
                                              ></div>
                                              <div
                                                className="set-primary"
                                                onClick={(e) =>
                                                  setPrimary(e, photo)
                                                }
                                              >
                                                {t(["set-primary", ""])}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {/*  */}

                  <div
                    className={`edit-element ${
                      profileCheck && profileData?.photos?.length === 0
                        ? "required"
                        : ""
                    }`}
                  >
                    <Tooltip
                      id="addPhoto-tooltip"
                      className="main-tooltips"
                      classNameArrow="custom-arrows"
                    />
                    <div
                      className={`add-photo ${
                        profileData?.photos?.length === 0
                          ? "profile-photo"
                          : " "
                      }`}
                    >
                      <div id="addPhoto">
                        <input
                          type="file"
                          name="photo"
                          className={`form-control ${
                            profileData?.photos?.length === 0
                              ? "profile-photo"
                              : " "
                          }`}
                          accept="image/*"
                          id="profile-photo"
                          onChange={imageSelect}
                        />
                        {profileData?.photos?.length === 0 ? (
                          <>
                            <button className="">
                              + {t(["add-photo", ""])}
                            </button>
                          </>
                        ) : (
                          <button className="">+ Add More Photos</button>
                        )}

                        {/* <div
                        className="tooltipprofile"
                        data-tooltip-id="addPhoto-tooltip"
                        data-tooltip-content="Add Photo"
                      >
                        !
                      </div> */}
                      </div>
                      <span className="photo-text">*Photo is required</span>
                    </div>
                    <br />
                    <br />
                    <div id="directPhotoWrap" className="cus_edit_profile_page">
                      <input
                        type="file"
                        id="directPhoto"
                        accept=".jpg, .jpeg, .png"
                        max
                        onChange={uploadImageDirect}
                      />
                      {language === "en" ? (
                        <>
                          <p>
                            Having trouble cropping your image? <br />
                            <a href="#">
                              {" "}
                              Click here to upload your photo directly without
                              manually cropping.
                            </a>{" "}
                            <br />
                            <span>
                              (Your photo will be cropped and resized
                              automatically.)
                            </span>
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            क्या आपको अपनी छवि काटने में परेशानी हो रही है?{" "}
                            <br />
                            <a href="#">
                              {" "}
                              अपनी फ़ोटो को मैन्युअल रूप से काटे बिना सीधे अपलोड
                              करने के लिए यहां क्लिक करें।
                            </a>{" "}
                            <br />
                            <span>
                              (आपकी फ़ोटो स्वचालित रूप से क्रॉप और आकार बदल
                              जाएगी।)
                            </span>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section end" id="section-end">
            {profileData?.likers ? (
              <>
                <div className="likers-wrap" id="likers-wrap">
                  <span className="heading small">
                    <span>{t(["profile-likes", ""])}</span>{" "}
                    {/* {console.log(profileData)} */}
                    {profileData?.is_premium || !profileData?.is_premium ? (
                      <>
                        <Link
                          to="/"
                          id="profileLikerViewAllBt"
                          onClick={(e) => showLikeUsersPopup(e)}
                        >
                          {t(["see-all", ""])}
                        </Link>
                      </>
                    ) : (
                      <Link
                        to="/premium"
                        onClick={(e) => upgradeSubscription(e)}
                      >
                        {t(["view-all-upgrade-button", ""])}
                      </Link>
                    )}
                  </span>
                  {/*  */}
                  {isMobile ? (
                    <>
                      {/* {console.log(viewerslidersettings)} */}
                      {profileData?.likers ? (
                        <>
                          <Slider
                            className="user-feeds"
                            id="likers"
                            {...likersslidersettings}
                          >
                            {Object.entries(profileData?.likers).map(
                              ([id, user]) => (
                                //
                                //
                                <>
                                  {profileData?.is_premium || !profileData?.is_premium ? (
                                    <>
                                      <div className="user">
                                        <Link
                                          className="nolinkstyle text-black"
                                          to={"/user/" + user?.slug}
                                        >
                                          <div className="user-photo">
                                            <img
                                              src={
                                                user?.photo
                                                  ? user?.photo
                                                  : imagePlacholder
                                              }
                                              alt={user?.name}
                                              onError={handleImageError}
                                            />
                                          </div>
                                        </Link>

                                        <span>{user?.name}</span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="user">
                                        <div className="user-photo">
                                          <img
                                            src={
                                              user?.photo
                                                ? user?.photo
                                                : imagePlacholder
                                            }
                                            alt={user?.name}
                                            onError={handleImageError}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                                //
                                //
                              )
                            )}
                          </Slider>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="user-feeds notmobile" id="likers">
                        {profileData?.likers ? (
                          <>
                            {Object.entries(profileData?.likers).map(
                              ([id, user]) => (
                                <>
                                  {profileData?.is_premium || !profileData?.is_premium ? (
                                    <>
                                      <div className="user">
                                        <Link
                                          className="nolinkstyle text-black"
                                          to={"/user/" + user?.slug}
                                          key={id}
                                        >
                                          <div className="user-photo">
                                            <img
                                              src={
                                                user?.photo
                                                  ? user?.photo
                                                  : imagePlacholder
                                              }
                                              alt={user?.name}
                                            />
                                          </div>
                                        </Link>
                                        <span>{user?.name}</span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="user">
                                        <div className="user-photo">
                                          <img
                                            src={
                                              user?.photo
                                                ? user?.photo
                                                : imagePlacholder
                                            }
                                            alt={user?.name}
                                            onError={handleImageError}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            {/* {profileData?.viewers ? (
              <>
                <div className="viewers-wrap" id="viewers-wrap">
                  <span className="heading mt-30 small">
                    <span>{t(["profile-viewers", ""])}</span>{" "}
                    {profileData?.is_premium ? (
                      <Link
                        to="/"
                        id="profileViewersViewAllBt"
                        onClick={(e) => showViewUsersPopup(e)}
                      >
                        {t(["see-all", ""])}
                      </Link>
                    ) : (
                      <Link
                        to="/premium"
                        onClick={(e) => upgradeSubscription(e)}
                      >
                        {t(["view-all-upgrade-button", ""])}
                      </Link>
                    )}
                  </span>

                  {profileData?.viewers ? (
                    <>
                      {isMobile ? (
                        <>
                          <Slider
                            className="user-feeds"
                            id="viewers"
                            {...viewerslidersettings}
                          >
                            {Object.entries(profileData?.viewers).map(
                              ([id, user]) => (
                                //
                                //
                                <>
                                  {profileData?.is_premium ? (
                                    <>
                                      <div className="user">
                                        <Link
                                          className="nolinkstyle text-black"
                                          to={"/user/" + user?.slug}
                                        >
                                          <div className="user-photo">
                                            <img
                                              src={
                                                user?.photo
                                                  ? user?.photo
                                                  : imagePlacholder
                                              }
                                              alt={user?.name}
                                              onError={handleImageError}
                                            />
                                          </div>
                                        </Link>

                                        <span>{user?.name}</span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="user">
                                        <div className="user-photo">
                                          <img
                                            src={
                                              user?.photo
                                                ? user?.photo
                                                : imagePlacholder
                                            }
                                            alt={user?.name}
                                            onError={handleImageError}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                                //
                                //
                              )
                            )}
                          </Slider>
                        </>
                      ) : (
                        <>
                          <div
                            className="user-feeds"
                            id="viewers"
                            {...viewerslidersettings}
                          >
                            {Object.entries(profileData?.viewers).map(
                              ([id, user]) => (
                                //
                                //
                                <>
                                  {profileData?.is_premium ? (
                                    <>
                                      <div className="user">
                                        <Link
                                          className="nolinkstyle text-black"
                                          to={"/user/" + user?.slug}
                                        >
                                          <div className="user-photo">
                                            <img
                                              src={
                                                user?.photo
                                                  ? user?.photo
                                                  : imagePlacholder
                                              }
                                              alt={user?.name}
                                              onError={handleImageError}
                                            />
                                          </div>
                                        </Link>

                                        <span>{user?.name}</span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="user">
                                        <div className="user-photo">
                                          <img
                                            src={
                                              user?.photo
                                                ? user?.photo
                                                : imagePlacholder
                                            }
                                            alt={user?.name}
                                            onError={handleImageError}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                                //
                                //
                              )
                            )}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )} */}

            <div className="edit-field">
              {profileattributesComplete ? (
                <button
                  className="main-bt"
                  id="saveProfileBt"
                  onClick={saveProfile}
                >
                  {t(["save-changes", ""])}
                </button>
              ) : (
                <>
                  <button
                    className="main-bt disabled"
                    id="saveProfileBt"
                    onClick={(e) => saveProfileIncomplete(e)}
                  >
                    {t(["save-changes", ""])}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
