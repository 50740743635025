import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ProfileBio from "./bio";
import ProfileAttrributeView from "./attributes";
import { useTranslation } from "react-i18next";
import { capitalizeWords } from "../../app/utilities/functions";
import {
  getLanguageFromLocalStorage,
  savePageTranslateLocalStorage,
  initializeI18n,
} from "../../app/utilities/localization";
import tickImage from '../../assets/images/tick2.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as clipboard from "clipboard-polyfill";
import { showAlert, showToolTip } from "../../components/showAlerts";
import placeholderImage from "../../assets/images/Main/user-placeholder.jpg";
// import axios from "axios";
import AxiosWithAuth from "../../app/auth/jwtMiddleware";

import { checkViewSize } from "../../app/utilities/profile-script";
import { Link } from "react-router-dom";

const url = process.env.REACT_APP_PUBLIC_URL;

export default function ProfileView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [viewdata, setviewData] = useState();
  const [likeChange, setLikeChange] = useState(false);
  const [ZoomedImage, setZoomedImage] = useState();
  const language = getLanguageFromLocalStorage() || "en";

  //
  const { profileData } = useSelector((state) => state.profile);
  // console.log(profileData)
  const [isMobile, setIsMobile] = useState();

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleImageError = (e) => {
    // Replace the failed image with a placeholder image
    e.target.src = placeholderImage; // Replace with the path to your placeholder image
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
    setviewData();
    const fetchData = async () => {
      await savePageTranslateLocalStorage("profile");
      initializeI18n([
        "religion",
        "language",
        "education",
        "career",
        "FamilyType",
        "familyValue",
        "maritalStatus",
        "diet",
        "children",
        "caste",
        "hobby",
        "premium",
      ]);
    };
    fetchData();
  }, []);

  async function sendMessage(messagelike) {
    setLoading(true);
    try {
      await AxiosWithAuth()
        .post(`${url}/chats/send-request`, messagelike)
        .then((data) => {
          setLoading(false);
          window.location.href = "/messages?u=" + data?.data?.data.id;
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          } else {
            showAlert("We have encountered an error");
          }
        });
    } catch (error) {
      setLoading(false);
      showAlert("We have encountered an error");
    }
  }

  const handleMessageUser = (e, data) => {
    // console.log(data)
    e.preventDefault(data);
    let messageData = new FormData();

    messageData.append("to", data?.main?.id);
    messageData.append("from", profileData?.main?.id);
    sendMessage(messageData);
  };

  useEffect(() => {
    var formData = new FormData();
    formData.append("language", language);
    formData.append("slug", slug);
    formData.append("viewer", profileData?.main?.id);
    getUser(formData);
  }, [likeChange]);

  useEffect(() => {
    checkViewSize();
    //

    if (window.innerWidth <= 767) {
      setIsMobile(true);
    } else if (window.innerWidth > 767) {
      setIsMobile(false);
    }

    //

    window.addEventListener(
      "resize",
      function (event) {
        checkViewSize();
        if (window.innerWidth <= 767) {
          setIsMobile(true);
        } else if (window.innerWidth > 767) {
          setIsMobile(false);
          checkViewSize();
        }
      },
      true
    );

    return () => {
      window.addEventListener("resize", function (event) {}, true);
    };
  }, []);

  const copyToClipboard = async (e, data) => {
    e.preventDefault();

    if (data?.profile?.slug) {
      var urltext = window.location.origin + "/user/" + data?.profile?.slug;
      try {
        await clipboard.writeText(urltext);
        showAlert("link copied to clipboard");
      } catch (error) {
        showAlert("Could not copy link");
      }
    } else {
      showAlert("Could not copy link");
    }
  };

  // get

  function getUser(data) {
    setIsLoading(true);
    try {
      AxiosWithAuth()
        .post(`${url}/users/find-by-slug`, data)
        .then((response) => {
          //  console.log("Sayed",response)
          setviewData(response?.data?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          // console.log(error?.response?.data?.message);
          showAlert(error?.response?.data?.message);
          setTimeout(() => {
            navigate("/");
          }, 1500);
        });
    } catch (error) {
      setIsLoading(false);
      // console.log(error)
    }
  }

  function requestMessage(e) {
    e.preventDefault();
    // console.log('send');
    // console.log(viewdata);
    setLoading(true);
    var data = {
      to: viewdata?.main?.id,
      from: profileData?.main?.id,
    };
    try {
      AxiosWithAuth()
        .post(`${url}/chats/send-request`, data)
        .then((res) => {
          if (res?.data?.data) {
            setLoading(false);
            // console.log(res.data?.data?.id)
            window.location.href = "/messages?u=" + res.data?.data?.id;
          }
          // console.log(res);
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          } else {
            showAlert("We have encountered an error");
          }
        });
    } catch (error) {
      setLoading(false);
      showAlert("We have encountered an error");
    }
  }

  const handlelikeUser = (e, data) => {
    e.preventDefault();

    let likeData = new FormData();
    // console.log(data)
    likeData.append("user_id", data?.main?.id);
    likeData.append("liker_id", profileData?.main?.id);

    // sliceLike(data);
    getLike(likeData);
  };

  //

  async function getLike(userdata) {
    setLoading(true);
    try {
      await AxiosWithAuth()
        .post(`${url}/users/like`, userdata)
        .then((data) => {
          if (data?.data?.status) {
            setLikeChange(!likeChange);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          } else {
            showAlert("We have encountered an error");
          }
          // dispatch(initializeErrorLiking());
        });
    } catch (error) {
      setLoading(false);
      showAlert("We have encountered an error");
      // console.log(error);
    }
  }
 
  const ImageZoom = (e, url) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    document.body.classList.add('no-scroll');
    setZoomedImage(url);
  };
  const RemoveImageZoom = (e, url) => {
    e.preventDefault();
    document.body.classList.remove('no-scroll');
    setZoomedImage(null);
  };


  const profileGallerySliderSettings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    infinite: false,
  };

  return (
    <>
      {loading ? (
        <>
          {" "}
          <div className="loader profile-loader"></div>
        </>
      ) : (
        <></>
      )}

      {isLoading ? (
        <>
          {" "}
          <div className="loader profile-load"></div>
        </>
      ) : (
        <>

          <div id="Profile">
            {ZoomedImage && (
          <div className="picture-zoom-area" >
            <div className="picture-zoom-area-inner">
              <div className="picture-zoom-area-inner-close"
              onClick={
                (e)=>RemoveImageZoom(e)
              }
              >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>

                </div>
              <img src={ZoomedImage} alt="" />
              </div>
            </div>
            )}

            <div id="profile-page" className="">
              <div className="section left">
                <div className="mobile-actions">
                  <Link
                    to="#"
                    className={`like-bt ${
                      viewdata?.main?.is_liked && "set_liked"
                    }`}
                    onClick={(e) => handlelikeUser(e, viewdata)}
                  ></Link>
                  <Link
                    to="/"
                    onClick={(e) => handleMessageUser(e, viewdata)}
                    className="message-bt"
                  ></Link>
                </div>
                <div className="left" id="mobile-left-bar">
                  <div id="primary-gallery">
                    <div
                      className={`column ${
                        viewdata?.is_premium ? "is_premium_user" : " "
                      }`}
                      id="photo-1"
                    >
                      <div className="photo">
                        {viewdata ? (
                          // console.log(profileData),
                          <>
                            {Object.entries(viewdata?.photos).map(
                              ([id, photo]) => (
                                <>
                                  {photo?.is_primary === 1 ? (
                                    <>
                                      <img
                                        // src={photo?.url}
                                        alt={viewdata?.last_name}
                                        src={
                                          photo?.url
                                            ? photo?.url
                                            : placeholderImage
                                        }
                                        onClick={(e) =>ImageZoom(e,photo?.url)}
                                        onError={handleImageError}
                                        key={"photos-main-" + id}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="edit-element">
                          <div className="delete-photo"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="user-name-tag-mobile-wrap"></div>
                  <ProfileAttrributeView data={viewdata?.profile} />
                </div>
              </div>

              <div className="right section" id="mid-section">
                <div className="edit-field">
                  <button className="main-bt" id="saveProfileBt">
                    {t(["save-changes", ""])}
                  </button>
                </div>

                <div className="top" id="top-main">
                  <div className="details" id="user-name-tag">
                    <div className="main">
                      <div className="name " id="profile-name">
                        {/* capitalizeWords(viewdata?.main?.name) */}
                        {typeof viewdata?.main?.name !== "undefined" &&
                          capitalizeWords(viewdata?.main?.name)}

      <p>
      <span  >{viewdata?.main?.id_veri_status === 1 && (
      <img src={tickImage} alt="Tick"  style={{height:'24px'}} />
      )}</span>
      <span className="age" style={{marginLeft:"10px"}}>{viewdata?.main?.age}</span></p>
                      </div>

                      <div id="location-tag-wrap">
                        <span className="attribute location" id="location-tag">
                          <label>{t(["location-label", ""])}</label>
                          <div className="setdata" id="profile-location">
                            {viewdata?.profile?.location?.full}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="actions">
                    <Link
                      to="#"
                      className="share-bt"
                      onClick={(e) => copyToClipboard(e, viewdata)}
                    ></Link>
                    <Link
                      to="#"
                      className={`like-bt  ${
                        viewdata?.main?.is_liked ? "active" : ""
                      }`}
                      onClick={(e) => handlelikeUser(e, viewdata)}
                    ></Link>
                    <Link
                      to="/"
                      className="main-bt"
                      onClick={(e) => requestMessage(e)}
                    >
                      {t(["send-message", ""])}
                    </Link>
                  </div>
                </div>

                <div className="bottom">
                  <ProfileBio data={viewdata?.profile} />
                </div>
                <div className="right">
                  <div id="user-photos-wrap">
                    <div id="user-photos">
                      <div id="profile-gallery" className="test test">
                        {viewdata ? (
                          <>
                            {isMobile ? (
                              <>
                                <Slider {...profileGallerySliderSettings}>
                                  {Object.entries(viewdata?.photos).map(
                                    ([id, photo]) => (
                                      // console.log(viewdata),
                                      //<>
                                      <>
                                        {photo?.is_primary === 0 ? (
                                          <div
                                            key={id + "-" + photo?.url}
                                            className={`column ${
                                              viewdata?.is_premium
                                                ? "is_premium_user"
                                                : " "
                                            }`}
                                          >
                                            <div
                                              className={`photo  ${
                                                profileData?.is_premium
                                                  ? ""
                                                  : " blur"
                                              }`}
                                            >
                                              <img
                                                src={photo?.url}
                                                alt={
                                                  profileData?.profile?.first_name +
                                                  " " +
                                                  profileData?.profile?.last_name
                                                }
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            <div
                                              key={id + "-" + photo?.url}
                                              className={` column ${
                                                viewdata?.is_premium
                                                  ? "is_premium_user"
                                                  : " "
                                              }`}
                                            >
                                              <div
                                                className={`photo  ${
                                                  photo?.is_blurred
                                                    ? "blur"
                                                    : " "
                                                }`}
                                              >
                                                <img
                                                  src={photo?.url}
                                                  alt={
                                                    profileData?.profile?.first_name +
                                                    " " +
                                                    profileData?.profile?.last_name
                                                  }
                                                />
                                                
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )
                                  )}
                                </Slider>
                              </>
                            ) : (
                              <>
                                {Object.entries(viewdata?.photos).map(
                                  ([id, photo]) => (
                                    // console.log(viewdata),
                                    //<>
                                    <>
                                      {photo?.is_primary === 0 ? (
                                        <div
                                          key={id + "-" + photo?.url}
                                          className={`column `}
                                        >
                                           <Link to="/premium-plan/">
                                          <div
                                            className={`photo  ${
                                              profileData?.is_premium
                                                ? ""
                                                : " blur"
                                            }`}
                                          >
                                                 
                                            <img
                                              src={photo?.url}
                                              alt={
                                                profileData?.profile?.first_name +
                                                " " +
                                                profileData?.profile?.last_name
                                              }
                                            />
                                          </div></Link>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
