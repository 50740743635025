import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  verificationPopup: null, // No popup by default
};

// Create slice
const verificationSlice = createSlice({
  name: "popup_slice_name", // Slice name
  initialState,
  reducers: {
    setVerificationPopup(state, action) {
      state.verificationPopup = action.payload; // Update state with the payload
    },
  },
});

// Export the action creator
export const { setVerificationPopup } = verificationSlice.actions;

// Export the reducer
export default verificationSlice.reducer;