import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import thunk from "redux-thunk";

import {
    registerActions,
    globalActions,
    profileActions,
    homeActions,
    messageActions,
    notificationActions,
    settingsActions
} from '../actions';

import popupReducer from '../actions/popupSlice';
//mk
// import mainPopupReducer from '../actions/MainPopup';

const persistConfig = {
    key: "root",
    storage,
  };

  
  const reducers = combineReducers({
     register:registerActions,
     global:globalActions,
     profile:profileActions,
     home:homeActions,
     messages:messageActions,
     notifications:notificationActions,
    setting:settingsActions,
    custom_storepopup:popupReducer 
  });

  const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

