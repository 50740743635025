import React,{useEffect, useState} from 'react'
import { useSelector,useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";
import { getLanguageFromLocalStorage } from "../../../app/utilities/localization"
import {
  mergeCountriesAndCodes,
} from "../../../app/utilities/functions";
import axios from "axios";
import AxiosWithAuth from "../../../app/auth/jwtMiddleware";
import {
  clearAllForms,
  getRegisterFields,
} from "../../../app/actions/Register";
import {
  getProfileData,
} from "../../../app/actions/Profile";

import { showAlert } from "../../showAlerts";
import SuccessImage from "../../../assets/images/success.png";

//mk start here
import { setVerificationPopup  } from '../../../app/actions/popupSlice'; // Import the action
//mk end here

const url = process.env.REACT_APP_PUBLIC_URL;

const PhoneVerification = ({profileData}) => {
  const [loading,setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [showPopup,setShowPopup] = useState(true);
  const [countrycodes, setCountryCodes] = useState([]);
  const [selectedcountrycodes, setSelectedCountryCodes] = useState("");
  const [countrycode, setCountryCode] = useState();
  const [mergedcountries, setMergedCountries] = useState();
  const [sendError, setSendError] = useState(false);
  const [phone, setPhone] = useState("");
  const { t } = useTranslation();
  const language = getLanguageFromLocalStorage() || "en";
  const [verifyPhoneWindow,setVrifyPhoneWindow] = useState(true);
  const [updatePhoneWindow,setupdatePhoneWindow] = useState(false);
  const [thankYouWindow,setupdateThankYouWindow] = useState(false);

  const [updatedPhoneNumber,setUpdatedPhoneNumber] = useState();
  const [optReset, setOptReset] = useState(0);
  const [optResetMessage, setOptResetMesage] = useState();
  const [finalMessage,setFinalMessage] = useState("Your phone number is verified successfully.");


  const dispatch = useDispatch();
  function closePopups(e) {
    e.preventDefault();
    setShowPopup(false);
    dispatch(setVerificationPopup(null)); 
  }
  const  handlePhoneScreen= (e) => {
     e.preventDefault();
    setupdatePhoneWindow(true);
    setVrifyPhoneWindow(false);
   };

   const  handleVerifyScreen= (e) => {
    e.preventDefault();
   setupdatePhoneWindow(false);
   setVrifyPhoneWindow(true);
  };

  const handleCountryCodes  = (data) => {
    // console.log(data);
    setSelectedCountryCodes(data);
    setCountryCode(data);
  };



  const {
    registrationFieldData,
  } = useSelector((state) => state.register);


  useEffect(() => {
    dispatch(getRegisterFields());
  }, []);
  useEffect(()=>{

    setMergedCountries(
    mergeCountriesAndCodes(
      registrationFieldData?.data?.data?.countries,
      registrationFieldData?.data?.data?.country_codes
    )
  );
  dispatch(clearAllForms());

}, [registrationFieldData, dispatch]);

const handleFormVerification =async (e) => {
  e.preventDefault();

  if (otp.length < 5) {
    showAlert("Invalid OTP");
    return;
  }
  setLoading(true);
  let data = {
    user_id: profileData?.main?.id,
    code: otp,
  };

    try{
      await axios.post(`${url}/users/verify-phone`, {...data, language}).then((res) => {
        setupdatePhoneWindow(false);
        setVrifyPhoneWindow(false);
        setupdateThankYouWindow(true);
        setLoading(false);
        var formData = new FormData();
        formData.append("slug", profileData?.profile?.slug);
        formData.append("language", language);
        dispatch(getProfileData(formData));
        setTimeout(()=>{
          setShowPopup(false); 
        },1500)
      })
      .catch(error=>{
        setLoading(false);
        showAlert(error?.response?.data?.data);
      });
    }
    catch (error){
      
    }
  };

  const handleUpdatePhone = (e) =>  {
    e.preventDefault();
    setLoading(true);
    let data = {
      user_id: profileData?.main?.id,
      phone: phone,
      country_code:countrycode
    };
    try {
      AxiosWithAuth()
        .post(`${url}/account/update-phone`, {...data, language})
        .then(({ data }) => {
          var formData = new FormData();
          formData.append("slug", profileData?.profile?.slug);

          formData.append("language", language);

          dispatch(getProfileData(formData));
          setLoading(false);
          setupdatePhoneWindow(false);
          if(countrycode==='971' || countrycode==='91' || countrycode==='92'){
            setVrifyPhoneWindow(true);            
          } else {
            setFinalMessage("Your phone has been updated.");
            setupdateThankYouWindow(true);
            setTimeout(()=>{
              setShowPopup(false); 
            },1500)
          }

        }).catch(error=>{
          setLoading(false);
          showAlert(error?.response?.data?.data);
        });
    } catch (error) {
      setLoading(false);
      showAlert(error?.response?.data?.data);
    }
  }

useEffect(()=>{
if(!profileData?.send_verification_otp){
  const mockEvent = {
    target: {
      value: 'mockevent',
    },
    preventDefault: () => {}, 
  };

  handleResendOTP(mockEvent);
  console.log("otp calling...");
}else{

  console.log(JSON.stringify(profileData, null, 2));
}
},[])


 function handleResendOTP(e) {
  e.preventDefault();
  setLoading(true);
  var data = {
    id: profileData?.main?.id,
    phone: profileData?.main?.phone,
    language: language,
    country_code: selectedcountrycodes,
  };
  try {
    axios
      .post(`${url}/users/get-verification-sms`, data)
      .then((response) => {
        setOptResetMesage(response?.data?.message);
        setLoading(false);

        if (response?.data?.data?.message) {
          showAlert(response.data.data.message);
      }
      
        setTimeout(() => {
          setOptReset(0);
          setOptResetMesage("");
        }, 10000);
      })
      .catch((error) => {
        setOptReset(2);
        setOptResetMesage(error?.response?.data?.data);
        showAlert(error?.response?.data?.data);
        setLoading(false);
        setTimeout(() => {
          setOptReset(0);
          setOptResetMesage("");
        }, 10000);
      });
  } catch (e) {
    setOptReset(2);
    setLoading(false);
    setOptResetMesage("We have encountered an error, please try again later");
    // console.log(e);
  }
}
  return (
    
    <div className={`phone-number-verification-popup popup-main ${showPopup && 'active'}` }>
{loading && (<div className="loader"></div>) }
      <div className={`phone-number-verification-wrap` }>
      <div className='close-area'>
        <button className='close-button-main'
        onClick={(e) => closePopups(e)}
        >X</button>
      </div>
      <div className={`enter-phone-windows verification-popup-window ${updatePhoneWindow ? 'active' : 'inactive'}`}>
            <div className="form-group">
                  <div className="label">
                  <h3>Enter Your Phone Number</h3>
                  </div>
                  <div className="cols dob-cols">
                    {/* <div className="cols short-first"> */}
                    <div className="" name="country_code">
                      <select
                        name="country_code"
                        id="field-country-codes"
                        value={countrycode}
                        onChange={(e) => handleCountryCodes(e.target.value)}
                        style={{ paddingRight: "45px", width: "100%" }}
                      >
                        {mergedcountries ? (
                          // console.log(mergedcountries),
                          <>
                            <option
                              key={"default-country-code"}
                              value=""
                              selected
                              disabled
                            >
                              {t(["select-code", "Select Code"])}
                            </option>
                            {mergedcountries && Object.entries(mergedcountries).map(
                              ([id, code]) => (
                                code?.phone !=='NULL' && (
                                <option
                                  key={"country-code-" + id}
                                  value={code?.phone}
                                >
                                  {code?.code && code?.name
                                    ? t([code?.code, code?.name])
                                    : code?.name}{" "}
                                  - (+{code?.phone}){" "}
                                </option> )
                              )
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>
                    <div className="mobile-top-margin" name="phone">
                      <input
                        type="text"
                        placeholder={t([
                          "mobile-number-label",
                          "Mobile Number",
                        ])}
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className={` ${(phone==="" && sendError)  && 'errorBorder'}`}
                        required
                      />
                    </div>

                    {/*  */}

                    <div className='buttons-area'>
                    <div className='resend-button'>
                      <button 
                      onClick={(e) => handleVerifyScreen(e)}
                      className="nav-bt float-start red-outline">Back</button>
                    </div>
                    <div className='verify-button'>
                      <button className='nav-bt red float-end'
                       onClick={(e) => handleUpdatePhone(e)}
                      >Save</button>
                      </div>
                  </div>
                  </div>

  
                </div>
            </div>
            <div className={`verify-phone-window verification-popup-window ${verifyPhoneWindow ? 'active' : 'inactive'}`}>
      <h3>Verify Your Phone Number</h3>
      <p>We have sent you a verification code via text on +{profileData?.main?.phone}</p>
      <div className="inputs">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={5}
                  //   inputType={'text'}
                  inputStyle={"otpinput"}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus={true}
                />
              </div>
              <div className='buttons-area'>
          <div className='resend-button'>
            <button className="nav-bt float-start red-outline"
           onClick={(e)=>handleResendOTP(e)}
            >Resend</button>
          </div>
          <div className='verify-button'>
            <button className='nav-bt red float-end'
             onClick={(e)=>handleFormVerification(e)}
            >Verify</button>
            </div>
        </div>
        <div className='its-not-my-numner'>
            <p>If this is not your number, <span 
                onClick={(e) => handlePhoneScreen(e)}
            className='click-button-popup'>click here</span> to update.</p>
            </div>
            </div>
            <div className={`final-phone-windows verification-popup-window ${thankYouWindow ? 'active' : 'inactive'}`}>
            <div className="final-group">
                  <div className='image-area'>
                    <img src={SuccessImage} alt = "all set image" />
                    </div>
                  <div className="label">
                    <h3>You're all set! </h3>
                  <p>{finalMessage}</p>
                  </div>
   


                    {/*  */}

                  </div>

  
                </div>
            </div>
        
        </div>

  )
}

export default PhoneVerification