import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showAlert } from "../../components/showAlerts";
import OtpInput from "react-otp-input";
import maintopbanner from "../../assets/images/home-banner-top-dtp.png";
import mainbottombanner from "../../assets/images/home-banner-bottom-dtp.png";

import {
  getRegisterFields,
  registerUser,
  campaign_registerUser,
  verifyPhone,
  clearAllForms,
  getCareers,
  getHobbies,
} from "../actions/Register";

import {
  getLanguageFromLocalStorage,
  savePageTranslateLocalStorage,
  initializeI18n,
  loadCities,
} from "../utilities/localization";
import AuthFooter from "./AuthFooter";
import AuthHeader from "./AuthHeader";
import { getCities, setUpdatedLanguage } from "../actions/Global";
import "./Register.scss";
import SuccessImage from "../../assets/images/success.png";
import {
  generateYears,
  generateDays,
  mergeCountriesAndCodes,
} from "../utilities/functions";
import axios from "axios";

import visibilityoff from "../../assets/images/visibility_off.svg";
import visibilityon from "../../assets/images/visibility.svg";

import sideloader from "../../assets/images/sidebuttonloader.gif";

import { Tooltip } from "react-tooltip";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-cropper";
import ImageCropper from "../../components/ImageCropper";

import { AutocompleteSelect } from "../../components";
// import GeoLocate from "../utilities/geolocation";

//

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 50,
        height: 50,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const url = process.env.REACT_APP_PUBLIC_URL;

export default function RegisterV2() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // GeoLocate();
  // console.log(id)
  const {
    registrationFieldData,
    isRegistrationLoading,
    errorMessageRegistration,
    registrationError,
    registrationSuccessful,
    errorOnRegistrationMessage,
    userdata,
    verificationError,
    errorVerificationMessage,
    verificationIsSuccessful,
    showEntryForm,
    showOTPForm,
    showSuccessForm,
    careerData,
    hobbiesData,
  } = useSelector((state) => state.register);

  const { citiesData, currentLanguage } = useSelector((state) => state.global);
  const [otp, setOtp] = useState("");
  const [enableverification, setEnableVerification] = useState(false);

  const [enableForm, setEnabledform] = useState(1);
  // class elements

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);
  const [step6, setStep6] = useState(false);
  const [step2error, setStep2Error] = useState(false);
  const [step2errorMessage, setStep2ErrorMessage] = useState("");
  const [step2errorImage, setStep2ErrorImage] = useState(false);
  const [step2errorImageMessage, setStep2ErrorImageMessage] = useState("");
  const [step3error, setStep3Error] = useState(false);
  const [step5Error, setStep5Error] = useState(false)
  const [step5Message ,setStep5ErrorMessage] = useState("")
  const [step3errorMessage, setStep3errorMessage] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [adUser, setAdUser] = useState(false);
  // network data
  const [genders, setGender] = useState([]);
  const [religions, setReligion] = useState([]);
  const [castes, setCastes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [profiletypes, setProfileTypes] = useState([]);
  const [countrycodes, setCountryCodes] = useState([]);
  const [cities, setCities] = useState([]);
  const [marriagestatuses, setMarriageStatuses] = useState([]);
  const [noCropperBrowsers, setNoCropperBrowsers] = useState([]);

  //
  const [children, setChildren] = useState([]);
  const [height, setHeight] = useState([]);
  const [heightObject, setHeightObject] = useState([]);
  const [career, setCareer] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [familyValue, setFamilyValues] = useState([]);
  const [familytype, setFamilyType] = useState([]);
  const [education, setEducation] = useState([]);
  const [diet, setDiet] = useState([]);
  const [primaryLanguage, setPrimaryLanguage] = useState([]);
  const [displayChildren, setDisplayChidren] = useState(false);

  //selected data
  const [selectedgender, setSelectedGender] = useState("");
  const [selectedprofiletype, setSelectedProfileType] = useState("");
  const [selectedereligion, setSelectedReligion] = useState("");
  const [selectedReligionName, setSelectedReligionName] = useState("");
  const [selectedcommunity, setSelectedCommunity] = useState("");
  const [selectedCommunityName, setSelectedCommunityName] = useState("");
  const [selectedcountry, setSelectedCountry] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [selectedcountrycodes, setSelectedCountryCodes] = useState("");
  const [selectedcities, setSelectedCities] = useState("");
  const [selectedCitiesName, setSelectedCitiesName] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [sendError, setSendError] = useState(false);
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedmonth, setSelectedMonth] = useState("");
  const [selecteddays, setSelectedDays] = useState("");
  const [selectedyear, selectedYear] = useState("");
  const [age, setAge] = useState();
  const [selectmarriagestatus, setMarriageStatus] = useState("");
  const [setmarriagestatuserror, setMarriageStatusError] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageCropper, setSelectedImageCropper] = useState(null);
  const [orignalImage, setOrignalImage] = useState(null);
  const [selectedChildren, setSelectedChildren] = useState();
  const [selectedHeight, setSelectedheight] = useState();
  const [selecteDiet, setSelecteDiet] = useState();
  const [selectedcareer, setSelectedCareer] = useState();
  const [selectedEducation, setSelectedEducation] = useState();
  const [selectfamilytype, setSelectedFamilyType] = useState();
  const [selectFamilyValue, setSelectedFamilyValue] = useState();
  const [selectLangauge, setSelectedLanguage] = useState();

  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [about, setAbout] = useState("");
  const [aboutError, setAboutError] = useState(false);
  const [lookingFor, setLookingFor] = useState("");
  const [lookingForError, setLookingForError] = useState(false);

  const [step1Error, setStep1Error] = useState(false);
  const [step1ErrorMessage, setStep1ErrorMessage] = useState("");

  const [dateError, setDateError] = useState(false)

  const [dayError, setDayError] = useState(false)
  const [monthError,setMonthError] = useState(false)
  const [yearError, setYearError] = useState(false)

  const [countrycode, setCountryCode] = useState();

  const [mergedcountries, setMergedCountries] = useState();

  const [optReset, setOptReset] = useState(0);
  const [optResetMessage, setOptResetMesage] = useState();

  const [emailReset, setEmailReset] = useState(0);
  const [emailResetMessage, setEmailResetMesage] = useState();
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [fileupload, setFileUpload] = useState("");
  const [iscrop, setIscrop] = useState(false);
  const [cropper, setCropper] = useState();

  const [blobImage, setBlobImage] = useState("");

  const [headingTextLeft, setHeadingText] = useState("Start Your");

  const month = [
    {
      name: "01",
    },
    {
      name: "02",
    },
    {
      name: "03",
    },
    {
      name: "04",
    },
    {
      name: "05",
    },
    {
      name: "06",
    },
    {
      name: "07",
    },
    {
      name: "08",
    },
    {
      name: "09",
    },
    {
      name: "10",
    },
    {
      name: "11",
    },
    {
      name: "12",
    },
  ];
  const [days, setDays] = useState([]);
  const [years, setYears] = useState([]);
  const language = getLanguageFromLocalStorage() || "en";

  const [isChecked, setIsChecked] = useState(false);
  const [isSmsChecked, setIsSmsChecked] = useState(true);
  const [termsmessage, setTermsMessage] = useState(false);
  const [smsTermsmessage, setSmsTermsMessage] = useState(false);


  const [validationResult, setValidationResult] = useState(null);
  const [phoneerror, setphoneError] = useState(null);
  const [emailvalidationResult, setEmailValidationResult] = useState(null);
  const [emailerror, setEmailError] = useState(null);


  const handlePhoneChangeAndValidate = async (event) => {

    const newPhone = selectedcountrycodes+phone;
    const newPhone1 = "917502263545";
    setphoneError(null);
    try {
      const response = await axios.get(
        `https://phonevalidation.abstractapi.com/v1/?api_key=fe07ff629eed4270b9ccacff52966e02&phone=${newPhone}`
      );

      console.log(JSON.stringify(response.data, null, 2));
      

      if (response.data.valid) {
       
        setValidationResult(<p>✅</p>);
        // return <img draggable="false" role="img" className="emoji" alt="✅" src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg" />;
      } else {
        setPhone(""); 
        setValidationResult("");
        setphoneError("Please enter a valid mobile number.");
        // setValidationResult( <div></div>);
        // return <div>Please enter a valid mobile number.</div>;
      }

      
    } catch (error) {
      console.error("Error validating phone number:", error);
      if (error.response && error.response.status === 429) {
        const errorMessage = error.response.data.valid || "Rate limit exceeded.";
        setphoneError(
          `${errorMessage}`
        );
        setValidationResult(null); 
        // Retry after a delay if needed (for example, after 60 seconds)
        setTimeout(() => {
          setphoneError(null); 
        }, 60000); // Retry delay in milliseconds (60 seconds)
      } else {
        // Other error handling
        setphoneError("An error occurred. Please try again.");
        setValidationResult(null);
      }
      
    }
  };

  const handleEmailChangeAndValidate = async (event) => {
    setphoneError(null);
    try {
      const response = await axios.get(
        `https://emailvalidation.abstractapi.com/v1/?api_key=7dafa7a964794dbdb161d7a055cf9b75&email=${email}`
      );

      // console.log(JSON.stringify(response.data, null, 2));
      console.log(response.data.deliverability);

      if (response.data.deliverability=="DELIVERABLE") {
       
        setEmailValidationResult(<p>✅</p>);
        setEmailError("");
      } else {
        setEmail(""); 
        setEmailValidationResult("");
        setEmailError("Please enter a valid email id.");
        // setValidationResult( <div></div>);
        // return <div>Please enter a valid mobile number.</div>;
      }

      
    } catch (error) {
      console.error("Error validating phone number:", error);
      if (error.response && error.response.status === 429) {
        const errorMessage = error.response.data.valid || "Rate limit exceeded.";
        setEmailError(
          `${errorMessage}`
        );
        setEmailValidationResult(null); 
        // Retry after a delay if needed (for example, after 60 seconds)
        setTimeout(() => {
          setEmailError(null); 
        }, 60000); // Retry delay in milliseconds (60 seconds)
      } else {
        // Other error handling
        setEmailError("An error occurred. Please try again.");
        setEmailValidationResult(null);
      }
      
    }
  };
 
  useEffect(() => {
    // Assuming you're not using a routing library
    const queryString = window.location.search; // Gets the query string from the URL
    const urlParams = new URLSearchParams(queryString);
    const adUserValue = urlParams.get('ad'); // Gets the value of 'ad_user' parameter
    if(adUserValue==="1")
    {
      setAdUser(true);
    }

  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSMSCheckboxChange = () => {
    setIsSmsChecked(!isSmsChecked);
  };
  //
  //
  //
  const [src, setSrc] = useState("");
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [imageRef, setImageRef] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [aspect, setAspect] = useState(1);

  const [isBrowser, setIsBrowser] = useState(true);

  useEffect(() => {
    localStorage.removeItem("persist:root");
    const fetchData = async () => {
      await savePageTranslateLocalStorage("registration");
      initializeI18n([
        "religion",
        "caste",
        "profileType",
        "maritalStatus",
        "countries",
        "genders",
      ]);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (language !== currentLanguage) {
      dispatch(setUpdatedLanguage(language));
      //dispatch(getRegisterFields());
    }
  }, [language]);

  

  useEffect(() => {
    // console.log(registrationFieldData)

    // alert(navigator.userAgent)
    // console.log(noCropperBrowsers)
    // console.log(noCropperBrowsers?.length)

    // alert(navigator.userAgent)
    if (
      navigator.userAgent.includes("FB_IAB") ||
      navigator.userAgent.includes("FB4A") ||
      navigator.userAgent.includes("FBAV") ||
      navigator.userAgent.includes("Instagram") ||
      navigator.userAgent.includes("instagram") ||
      navigator.userAgent.includes("Android")
    ) {
      setIsBrowser(false);
      // alert(navigator.userAgent)
      // console.log('edge')
    }
    // else if(navigator.userAgent.includes('Android 10') &&  noCropperBrowsers?.some(browser => navigator.userAgent.includes(browser))){
    //   setIsBrowser(false);
    // }

    // dispatch(getRegisterFields());

    // console.log(registrationFieldData);
    // console.log(careerData)

    if (registrationFieldData?.length === 0) {
    } else {
      setProfileTypes(registrationFieldData?.data?.data?.profile_types);
      setSelectedProfileType(
        registrationFieldData?.data?.data?.profile_types[0]?.slug
      );

      setChildren(registrationFieldData?.data?.data?.children);
      if (registrationFieldData?.data?.data?.children) {
        setSelectedChildren(
          registrationFieldData?.data?.data?.children[0]?.slug
        );
      }
      setHeight(registrationFieldData?.data?.data?.height);
      setDiet(registrationFieldData?.data?.data?.diet);

      setFamilyType(registrationFieldData?.data?.data?.family_type);
      setFamilyValues(registrationFieldData?.data?.data?.family_value);
      setEducation(registrationFieldData?.data?.data?.education);
      setPrimaryLanguage(registrationFieldData?.data?.data?.languages);
      setNoCropperBrowsers(
        registrationFieldData?.data?.data?.no_cropper_browsers
      );

      // setMarriageStatus(
      //   registrationFieldData?.data?.data?.marital_status[0]?.slug
      // );
      setGender(registrationFieldData?.data?.data?.genders);
      // setSelectedGender(registrationFieldData?.data?.data?.genders[0]?.slug);

      setReligion(registrationFieldData?.data?.data?.religions);
      // setSelectedReligion(
      //   registrationFieldData?.data?.data?.religions[0]?.slug
      // );

      setCastes(registrationFieldData?.data?.data?.castes);
      // setSelectedCommunity(registrationFieldData?.data?.data?.castes[0]?.slug);

      setCountries(registrationFieldData?.data?.data?.countries);
      // setSelectedCountry(registrationFieldData?.data?.data?.countries[0].code);

      setCountryCodes(registrationFieldData?.data?.data?.country_codes);
      // setSelectedCountryCodes(
      //   registrationFieldData?.data?.data?.country_codes[971]
      // );
      setMarriageStatuses(registrationFieldData?.data?.data?.marital_status);

      //
      // setSelectedMonth(month[0]?.name);
      setDays(generateDays());
      // setSelectedDays("");
      // generateYears();

      setYears(generateYears());
      // selectedYear(generateYears()[0]?.name);

      setMergedCountries(
        mergeCountriesAndCodes(
          registrationFieldData?.data?.data?.countries,
          registrationFieldData?.data?.data?.country_codes
        )
      );
      dispatch(clearAllForms());
      setSubmitErrorMessage("");
      // console.log(generateYears())
    }

    // setCareers()

    // dispatch(getWishlist());
  }, [registrationFieldData, dispatch]);




  useEffect(() => {

    // 
    const uniqueHeights = Object.values(height)
  .filter((value, index, self) => {
    const isUnique = self.findIndex(h => h.slug === value.slug) === index;
    // console.log(`Slug: ${value.slug}, isUnique: ${isUnique}`);
    return isUnique;
  });

// 
setHeightObject(uniqueHeights)

             
  }, [height])
  

  useEffect(() => {
    setCareer(careerData?.data?.data);
  }, [careerData]);

  useEffect(() => {
    // console.log(hobbiesData?.data?.data);
    setHobbies(hobbiesData?.data?.data);
  }, [hobbiesData]);

  useEffect(() => {
    dispatch(getRegisterFields());
    dispatch(getCareers());
    dispatch(getHobbies());
  }, []);

  useEffect(() => {
    
    if (showEntryForm) {
      setEnabledform(1);
    }
    if (showOTPForm) {
      if(userdata?.otp_send) {
       setEnabledform(2);
    } else {
        setEnabledform(4);
      }

    }
    if (showSuccessForm) {
      setEnabledform(3);
      // setEnabledform(4)
    }
    // setEnabledform(4);
    // console.log(registrationFieldData);
  }, [showEntryForm, showOTPForm, showSuccessForm]);

  useEffect(() => {
    if (citiesData?.data) {
      setCities([]);
      setTimeout(() => {
        setCities(citiesData?.data);
      }, 100);
      setSelectedCities("");
    }
  }, [citiesData]);

  useEffect(() => {
    // if(selectedereligion)
    //
    //

    let replacements = {
      XXXRELIGIONXXX: selectedReligionName
        ? selectedReligionName?.name && selectedReligionName?.slug
          ? t([selectedReligionName.slug, selectedReligionName.name])
          : selectedReligionName?.name
        : "",
      XXXCOMMUNITYXXX: selectedCommunityName
        ? selectedCommunityName?.name && selectedCommunityName?.slug
          ? t([selectedCommunityName.slug, selectedCommunityName.name])
          : selectedCommunityName?.name
        : "",
      XXCTYXX: selectedCitiesName
        ? selectedCitiesName?.name && selectedCitiesName?.slug
          ? t([selectedCitiesName.slug, selectedCitiesName.name])
          : selectedCitiesName?.name
        : "",
      XXXCNTRYXXX: selectedCountryName
        ? selectedCountryName?.name && selectedCountryName?.code
          ? t([selectedCountryName.code, selectedCountryName.name])
          : selectedCountryName?.name
        : "",
      XXXAGEXXX: age,
    };
   // console.log("check language : ",language);
    if (language === "en") {
      let originalString =
      (t(["registration-bio-content",
        "I am a XXXRELIGIONXXX from the XXXCOMMUNITYXXX community. I am currently settled in XXCTYXX, XXXCNTRYXXX. I am XXXAGEXXX years old. I look forward to finding someone to spend the rest of my life with!"]));

      // Define the replacements

      let modifiedString = originalString.replace(
        /XXXRELIGIONXXX|XXXCOMMUNITYXXX|XXCTYXX|XXXCNTRYXXX|XXXAGEXXX/g,
        (match) => replacements[match]
      );

      // setAbout(modifiedString);
      // setLookingFor(
        // t(["registration-bio-search-content","As I embrace the next chapter of my life, I'm excited to explore the possibilities of finding a lifelong partner who shares my values, dreams, and aspirations. I'm a compassionate and driven individual with a zest for adventure."])

   

    setAbout("")
    setLookingFor("")
    } else if (language === "in") {
      //

      let originalString =
      (t(["registration-bio-content",
        "मैं XXXCOMMUNITYXXX समुदाय से XXXRELIGIONXXX हूं।. मैं वर्तमान में XXCTYXX, XXXCNTRYXXX में बसा हुआ हूं। मैं XXXAGEXXX वर्ष का हूं।. मैं अपना शेष जीवन बिताने के लिए किसी को ढूँढ़ने का इंतज़ार कर रहा हूँ"]));

      // Define the replacements

      let modifiedString = originalString.replace(
        /XXXRELIGIONXXX|XXXCOMMUNITYXXX|XXCTYXX|XXXCNTRYXXX|XXXAGEXXX/g,
        (match) => replacements[match]
      );

      // setAbout(modifiedString);

      // setLookingFor(
      //   t(["registration-bio-search-content","जैसा कि मैं अपने जीवन के अगले अध्याय को स्वीकार कर रहा हूं, मैं एक आजीवन साथी खोजने की संभावनाओं का पता लगाने के लिए उत्साहित हूं जो मेरे मूल्यों, सपनों और आकांक्षाओं को साझा करता हो। मैं एक दयालु और साहसी व्यक्ति हूं जिसमें रोमांच का शौक है।."]))

        setAbout("")
        setLookingFor("")

    }


  }, [
    selectedReligionName,
    selectedCommunityName,
    selectedCitiesName,
    selectedCountryName,
    age,
    language,
    t,
  ]);

  function isLeap(year) {
    // Return true if year
    // is a multiple of 4 and
    // not multiple of 100.
    // OR year is multiple of 400.
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  function isValidDate(d, m, y) {

    setDayError(false);
    setMonthError(false);
    setYearError(false)


    if (d < 1 || d > 31) {
      setDayError(true)
      return false;
    }

    if (m < 1 || m > 12) {
      setMonthError(true);
      return false;} 

    // Handle February month
    // with leap year
    if (m == 2) {
      if (isLeap(y)) return d <= 29;
      else return d <= 28;
    }

    if (y === "") {
      setYearError(true)
      return false;
    }

    // Months of April, June,
    // Sept and Nov must have
    // number of days less than
    // or equal to 30.
    if (m === 4 || m === 6 || m === 9 || m === 11) return d <= 30;

    return true;
  }

  // function checkAge(d, m, y) {
  //   let date = new Date();
  //   let dateentered = new Date(m + "-" + d + "-" + y);
  //   // console.log(dateentered)
  //   let dif = Math.abs(date - dateentered);
  //   let ds = dif / (1000 * 3600 * 24 * 365);
  //   if (ds >= 18) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  function checkAge(d, m, y) {
    let today = new Date();
    let birthDate = new Date(y, m - 1, d); 

    let age = today.getFullYear() - birthDate.getFullYear();
    let mDiff = today.getMonth() - birthDate.getMonth();
    if (mDiff < 0 || (mDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

        if (age >= 18) {
      return true;
    } else {
      return false;
    }
}

  function getAge(d, m, y) {
    let date = new Date();
    let dateentered = new Date(m + "-" + d + "-" + y);
    let dif = Math.abs(date - dateentered);
    let ds = dif / (1000 * 3600 * 24 * 365);
    return Math.floor(ds);
  }

  function dateVerification() {
    let validation = true;
    setDateError(false)
    // console.log(selectedyear)
    if (!isValidDate(selecteddays, selectedmonth, selectedyear)) {
      setStep1Error(true);
      setStep1ErrorMessage("error-date");
      setDateError(true)
      validation = false;
      return validation;
    }

    setAge(getAge(selecteddays, selectedmonth, selectedyear));

    if (!checkAge(selecteddays, selectedmonth, selectedyear)) {
      setStep1Error(true);
      setStep1ErrorMessage("The minimum age of use is 18");
      // console.log()
      validation = false;
      return validation;
    }
    return validation;
  }

  const handleProfileType = (data) => {
    // console.log(data.slug)
    //  setSelectedProfileType(data.slug);
    setSelectedProfileType(data);
  };

  const handleMarriageStatus = (data) => {
    if (data === "married") {
      setMarriageStatusError(true);
    } else {
      setMarriageStatusError(false);
      setMarriageStatus(data);
    }
    if (data !== "single") {
      setDisplayChidren(true);
      return;
    }
    setDisplayChidren(false);
  };

  const handleGender = (data) => {
    setSelectedGender(data);
  };

  const handleChildren = (data) => {
    setSelectedChildren(data);
  };
  const handleCareer = (data) => {
    setSelectedCareer(data);
  };

  const handleEducation = (data) => {
    setSelectedEducation(data);
  };

  const handleFamilyType = (data) => {
    setSelectedFamilyType(data);
  };

  const handleFamilyValue = (data) => {
    setSelectedFamilyValue(data);
  };

  const handleDiet = (data) => {
    setSelecteDiet(data);
  };

  const handleHeight = (data) => {
    setSelectedheight(data);
  };

  const handleLanguage = (data) => {
    setSelectedLanguage(data);
  };

  const handleCity = (data) => {
    setSelectedCities(data?.slug);
    setSelectedCitiesName(data);
  };

  const handleCountry = (data) => {
    // e.preventDefault();
    // console.log(data);

    setSelectedCities("");
    setSelectedCountry(data?.code);
    loadCities(data?.code);
    dispatch(getCities(data?.code));
    setCountryCode(data?.phone);
    setSelectedCountryCodes(data?.phone);
    setSelectedCountryName(data);
    //  {country.code + "-phone-" + country.phone}
    // const parts = e.target.value.split("-phone-");
    // if (parts.length > 0) {
    //   const textBeforePhone = parts[0];
    //   setSelectedCountry(textBeforePhone);
    //   loadCities(textBeforePhone);
    //   dispatch(getCities(textBeforePhone));
    // }
    // if (parts.length > 1) {
    //   const textAfterPhone = parts[1];
    //   setCountryCode(textAfterPhone);
    //   setSelectedCountryCodes(textAfterPhone);
    //   // console.log(textAfterPhone); // This will log "674"
    // } else {
    //   setCountryCode("");
    //   setSelectedCountryCodes("");
    // }
  };

  const handleDate = (data) => {
    setSelectedDays(data);
  };

  const handleMonth = (data) => {
    setSelectedMonth(data);
  };

  const handleYear = (data) => {
    // console.log(data);
    selectedYear(data);
  };

  const handleReligion = (data) => {
    setSelectedReligion(data?.slug);
    setSelectedReligionName(data);
  };
  const handleCommunity = (data) => {
    setSelectedCommunity(data?.slug);
    setSelectedCommunityName(data);
    // setSelectedCommunityName(data?.name && data?.slug ?t([data.slug, data.name]): data?.name);
  };

  const handleCountryCodes = (data) => {
    // console.log(data);
    setSelectedCountryCodes(data);
    setCountryCode(data);
  };

  // const handleHobbies = (data) =>{
  //     console.log(data)
  // }

  const handleHobbies = (data) => {
    // console.log(hobbies)

    let objects1 = [];
    setSelectedHobbies([]);
    if (selectedHobbies) {
      objects1 = selectedHobbies;
      var val = (e) => e.slug === data.slug;

      if (objects1.some(val)) {
        //
        let index = objects1.findIndex(val);
        // console.log(objects.findIndex(val))
        objects1.splice(index, 1);
      } else {
        var dta = getHobbiesBySlug(data);
        // console.log(dta);
        // console.log(getLanguageBySlug(data))
        // objects.push(dta.slug);
        var newobject = [...objects1, dta];
        objects1 = newobject;
      }
    } else {
      var dta1 = getHobbiesBySlug(data);
      objects1.push(dta1);
    }
    // console.log(objects);
    setTimeout(() => {
      if (objects1.length > 0) {
        var slug = [];
        objects1.forEach((e) => {
          // console.log(e)
          slug.push(e.slug);
        });
        // setselecthobbies(slug);
      }
      setSelectedHobbies(objects1);
    }, 0);
    // console.log(objects1);
  };

  const getHobbiesBySlug = (dta) => {
    var itms = hobbies;
    return itms.find((item) => item.slug === dta.slug);
  };

  const removeHobbies = (e, value) => {
    e.preventDefault();

    // console.log(value)
    // console.log(hobbies)
    var sels = selectedHobbies;
    setSelectedHobbies([]);
    // console.log(sels);
    sels.splice(value, 1);
    // console.log(sel);
    setTimeout(() => {
      if (sels.length > 0) {
        var slug = [];
        sels.forEach((e) => {
          // console.log(e)
          slug.push(e.slug);
        });
        // setselecthobbies(slug);
      }
      setSelectedHobbies(sels);
      //  setFetchCarriers(!fetchcarrier);
    }, 0);
    // console.log(sels);
  };
  const handleStep1 = () => {
    setStep1Error(false);
    // let valid = dateVerification();
    // // console.log(selecteddays, selectedmonth, selectedyear);
    // // console.log(valid);
    // // console.log(valid);

    // if (!valid) {
    //   // setStep2Error(true);
    //   // setStep2ErrorMessage("Date Error");
    //   return;
    // }


    
    if(email ==="" || phone===""){
      setSendError(true)
      setSubmitError(true);
      setSubmitErrorMessage("error-details");
      return
    }

    setStep6(true);
    setStep1(false);
    // if (
    //   name !== "" &&
    //   lastname !== "" &&
    //   selectedgender &&
    //   selectedprofiletype &&
    //   selectedChildren &&
    //   selectmarriagestatus
    // ) {
    //   setStep1(false);
   
    // } else {
    //   setStep1Error(true);
    //   setStep1ErrorMessage("error-details");
    // }
    scrollToTop()
  };

  const handleStep2Back = () => {
    // if (selectedgender && selectedprofiletype) {
    setStep1(true);
    setStep2(false);
    setStep6(false);
    scrollToTop()
    // }
  };

  const handleStep2Next = () => {
    setStep2Error(false);
    setStep2ErrorImage(false);
    // console.log("step 2 error");

    if (selectedcountry === "" || !selectedcountry) {
      setStep2Error(true);
      setStep2ErrorMessage("error-details");
      return;
    }

    if (
      selectedereligion === "" ||
      !selectedereligion ||
      !selectedcommunity ||
      !selectLangauge
    ) {
      setStep2Error(true);
      setStep2ErrorMessage("error-details");
      return;
    }

    if (selectedcities === "" || !selectedcities) {
      setStep2Error(true);
      setStep2ErrorMessage("error-details");
      return;
    }

    setStep2(false);
    setStep3(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    scrollToTop()
  };

  const handleStep3Back = () => {
    setStep2(true);
    setStep3(false);
    scrollToTop()
  };

  const handleStep3Next = () => {
    // console.log(selectedcommunity);
    // console.log(selectedereligion);
    // console.log(selectedcountry);
    // console.log(selectedcities);
    setStep3Error(false);
    setStep3errorMessage("");
    if (
      !selectedcareer ||
      !selectedEducation ||
      !selectfamilytype ||
      !selectFamilyValue ||
      !selectedHeight ||
      !selecteDiet
    ) {
      setStep3Error(true);
      setStep3errorMessage("error-details");
      return;
    }

    setStep3(false);
    setStep4(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    scrollToTop()
  };

  const handleStep4Back = () => {
    setStep3(true);
    setStep4(false);
    scrollToTop()
  };

  const handleStep4Next = () => {
    setStep2ErrorImage(false);
    setStep2ErrorImageMessage("");
    if (!fileupload && !blobImage) {
      setStep2ErrorImage(true);
      setStep2ErrorImageMessage("Please upload a photo");
      return;
    }

    if (selectedHobbies?.length <= 0) {
      setStep2ErrorImage(true);
      setStep2ErrorImageMessage(t("error-details"), "Please fill all details");
      return;
    }
    setStep4(false);
    setStep5(true);
    scrollToTop()
  };

  const handleStep5Back = () => {
    setStep4(true);
    setStep5(false);
    scrollToTop()
  };
  const handleStep5Next = () => {
    setStep5Error(false)
    setAboutError(false)
    setLookingForError(false)
    setStep5ErrorMessage("")

    if(about==="" || lookingFor===""){
      setStep5ErrorMessage(t("error-details"), "Please fill all details")
      setStep5Error(true)
      return false
    }
    if (!checkParagraphLength(about, 10)) {
      setStep5ErrorMessage("The about us text is too short")
     setStep5Error(true)
     setAboutError(true)
      return false;
    }

    if (!checkParagraphLength(lookingFor, 10)) {
      setStep5ErrorMessage("The details of your ideal partner is too short");
      setStep5Error(true)
      setLookingForError(true)
      return false;
    }
    setStep6(true);
    setStep5(false)
    scrollToTop()
  };

  const handleStep6Back =() =>{
   
    setStep5(true);
    setStep6(false);
    scrollToTop()
  }

  const scrollToTop = () =>{
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }

  function checkParagraphLength(paragraph, minLength, maxLength) {
    const length = paragraph.length;
    return length >= minLength;
  }

  function passwordRegex(pass) {
    var regex = "^(?=.*[A-Za-z])(?=.*d)[A-Za-zd]{8,}$";
    var isNonWhiteSpace = /^\S*$/;

    if (!isNonWhiteSpace.test(pass)) {
      setSubmitErrorMessage("error-password-space");
      return false;
    }

    // const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    // if (!isContainsUppercase.test(pass)) {
    //   setSubmitErrorMessage("error-password-upper");
    //   return false;
    // }

    // const isContainsLowercase = /^(?=.*[a-z]).*$/;
    // if (!isContainsLowercase.test(pass)) {
    //   setSubmitErrorMessage("error-password-lower");
    //   return false;
    // }

    const isContainsNumber = /^(?=.*[0-9]).*$/;
    if (!isContainsNumber.test(pass)) {
      setSubmitErrorMessage("error-password-digit");
      return;
    }
    const isValidLength = /^.{8,}$/;
    if (!isValidLength.test(pass)) {
      setSubmitErrorMessage("error-password-lenght");
      return false;
    }
    return true;
  }

  const handleFormSubmit = (e) => {

    console.log("mk calling form submit...");
    
    e.preventDefault();
    setSubmitError(false);
    setSendError(false)

    // 

    if(email ==="" || password==="" || phone===""){
      setSendError(true)
      setSubmitError(true);
      setSubmitErrorMessage("error-details");
      return
    }


    if (!passwordRegex(password)) {
      setSubmitError(true);

      return;
    }



    let newphone = phone;

    // Remove leading zeros using a regular expression
    newphone = newphone.replace(/^0+/, "");

    // console.log(fileupload);

    // Update the input field with the stripped value
    // input.value = value;
    setPhone(newphone);

    //  let date = [
    //      da
    //  ]
    const dateObject = {
      day: selecteddays,
      month: selectedmonth,
      year: selectedyear,
    };

    // console.log(selectedHobbies);
    let data = {
      profile_type: selectedprofiletype,
      gender: selectedgender,
      first_name: name,
      last_name: lastname,
      date_of_birth: selectedyear + "-" + selectedmonth + "-" + selecteddays,

      religion: selectedereligion,
      caste: selectedcommunity,
      country: selectedcountry,
      city: selectedcities,
      email: email,
      country_code: selectedcountrycodes,
      phone: newphone,
      password: password,
      marital_status: selectmarriagestatus,
      photo: fileupload,
      photo_blob: blobImage,
      original_photo: orignalImage,
      children: selectedChildren,
      height: selectedHeight,
      diet: selecteDiet,
      career: selectedcareer,
      education: selectedEducation,
      family_type: selectfamilytype,
      family_value: selectFamilyValue,
      language: selectLangauge,
      bio:about,
      search_bio:lookingFor,
      adUser:adUser
      // marriage_status: selectmarriagestatus,
      // country_code: "+971",
    };

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (selectedHobbies.length > 0) {
      selectedHobbies.forEach((element) => {
        formData.append("hobbies[]", element.slug);
      });
    }
    // state : "af-farah",
    // console.log(formData);
    // console.log(data);
    dispatch(campaign_registerUser(formData));
  };

  const handleFormVerification = (e) => {
    e.preventDefault();

  
    // console.log(otp);
    setEnableVerification(false);
    // console.log(otp)
    if (otp.length < 5) {
      setEnableVerification(true);
      return;
    }

    let data = {
      user_id: userdata.id,
      code: otp,
    };

    dispatch(verifyPhone(data));
    // console.log(otp.length)
  };

  function handleLogin(e) {
    e.preventDefault();

    window.location.href = "/login-test/";
    dispatch(clearAllForms());
    setEnabledform(1);
  }

  function handleVerifyUsingEmail(e) {
    e.preventDefault();
    console.log("click email verificaton shown");
    sendEmailVerification();
  }

  function sendEmailVerification() {
    // $('.formwrap').removeClass('active');
    // $('#email-verify').addClass('active');

    var data = {
      email: email,
      language: language,
    };

    try {
      axios
        .post(`${url}/users/create-email-verification`, data)
        .then((response) => {
          setEnabledform(4);
          dispatch(clearAllForms());
          // console.log(response);
        })
        .catch((error) => {
          // showAlert(error?.response?.data?.data)
          // console.log(error);
        });
    } catch (error) {
      // console.log(error);
    }
  }

  // const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  //  console.log(registrationError);
  
  function handleEmailResendOTP(e) {
    e.preventDefault();
    // const
    var data = {
      id: userdata?.id,
      // phone: userdata?.phone,
      email: email,
      language: language,
    };
   // console.log("get-verification-sms "+data);
    //console.log(`get-email-code : ${JSON.stringify(data)}`);
    try {
      setIsLoadingEmail(true);
      axios
        .post(`${url}/users/create-edit-email-verification`, data)
        .then((response) => {
          console.log(`email response : ${JSON.stringify(response)}`);
          setEmailReset(1);
          setEmailResetMesage(response?.data?.message);
          setIsLoadingEmail(false);
          setTimeout(() => {
            setEmailReset(0);
            setEmailResetMesage("");
          }, 10000);
        })
        .catch((error) => {
          setEmailReset(2);
          setEmailResetMesage(error?.response?.data?.data);
          setIsLoadingEmail(false);
          setTimeout(() => {
            setEmailReset(0);
            setEmailResetMesage("");
          }, 10000);
        });
    } catch (e) {
      setEmailReset(2);
      setIsLoadingEmail(false);
      setEmailResetMesage("We have encountered an error, please try again later");
      // console.log(e);
    }
  }


  function handleResendOTP(e) {
    e.preventDefault();
    // const
    var data = {
      id: userdata?.id,
      // phone: userdata?.phone,
      phone: phone,
      country_code: selectedcountrycodes,
      language: language,
    };
   // console.log("get-verification-sms "+data);
    console.log(`get-verification-sms : ${JSON.stringify(data)}`);
    try {
      setIsLoadingOtp(true);
      axios
        .post(`${url}/users/get-verification-sms`, data)
        .then((response) => {
          setOptReset(1);
          setOptResetMesage(response?.data?.message);
          setIsLoadingOtp(false);
          setTimeout(() => {
            setOptReset(0);
            setOptResetMesage("");
          }, 10000);
        })
        .catch((error) => {
          setOptReset(2);
          setOptResetMesage(error?.response?.data?.data);
          setIsLoadingOtp(false);
          setTimeout(() => {
            setOptReset(0);
            setOptResetMesage("");
          }, 10000);
        });
    } catch (e) {
      setOptReset(2);
      setIsLoadingOtp(false);
      setOptResetMesage("We have encountered an error, please try again later");
      // console.log(e);
    }
  }

  function handleTerms(e) {
    e.preventDefault();
    setTermsMessage(true);
  }
  function handleSmsTerms(e){
    e.preventDefault();
    setSmsTermsMessage(true);
  }

  function uploadImageDirect(e) {
    e.preventDefault();
    const file = e.target.files[0];
    setBlobImage("");
    setSelectedImageCropper(null);
    setIscrop(false);
    setIsBrowser(true);
    if (!file) {
      return false;
    }

    setTimeout(() => {
      setFileUpload(e.target.files[0]);
    }, 0);

    // Create a URL for the selected image
    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(imageUrl);
  }

  const uploadCropImage = (e) => {
    // console.log(e.target.files);
    var files = e.target.files;

    var reader;
    var file;

    const imageUrl = URL.createObjectURL(files[0]);

    var done = function (url) {
      //  image.src = url;

      setSelectedImageCropper(url);
      setIscrop(true);
      // setSelectedImage(true)
    };

    if (files && files.length > 0) {
      if (files[0].size > 10 * 1024 * 1024) {
        showAlert("Please select an image less than 10MB.");
        return;
      }
      const reader1 = new FileReader();
      reader1.onload = () => {
        setOrignalImage(reader1.result);
        // console.log(reader1.result);
      };
      reader1.readAsDataURL(e.target.files[0]);
    }

    //
    if (files && files.length > 0) {
      file = files[0];

      if (URL) {
        done(URL.createObjectURL(file));
      } else if (FileReader) {
        reader = new FileReader();
        reader.onload = function (e) {
          // setOrignalImage(reader.result);
          done(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const onImageLoaded = (e) => {
    // console.log("image loaded");
    // setImageRef(e);

    if (aspect) {
      const { width, height } = e.currentTarget;
      // console.log(width)
      // console.log(height)
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };
  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.jpeg"
      );
      setCroppedImage(croppedImageUrl);
    }
  };
  //

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = fileName;
        resolve(window.URL.createObjectURL(blob));
      }, "image/jpeg");
    });
  };
  const androidCropImage = (easyCropedImage) => {
    setSelectedImageCropper(null);
    setIscrop(false);
    setSelectedImage(easyCropedImage);
    setBlobImage(easyCropedImage);
  };
  const cropImage = () => {
    var imagedata = "";

    if (typeof cropper !== "undefined") {
      // console.log(cropper.getCroppedCanvas().toDataURL());
      imagedata = cropper
        .getCroppedCanvas({
          width: 600,
          height: 600,
          // Limit max sizes
          maxWidth: 2000,
          maxHeight: 2000,
        })
        .toDataURL();

      // var file = new File(imagedata, 'mohabbatein-upload')
      setSelectedImageCropper(null);
      setIscrop(false);
      setSelectedImage(imagedata);
      setBlobImage(imagedata);
    }
  };

  const removeImage = (e) => {
    e.preventDefault();
    setSelectedImage("");
    setBlobImage("");
    setSelectedImageCropper(null);
    setIscrop(false);
  }

  return (
    <div id="Register">
      {/* <AuthHeader /> */}

      <section id="main" className="cus_loginbackground cus_login_design">
        {/* <div className="left-top heart"></div>
        <div className="left-bot heart"></div> */}
    <div style={{ position: 'absolute', top: 0, left: 0 ,right:0 , textAlign: 'center' }} >
      <img className="w-100-old img-fluid " src={ maintopbanner } alt="" />
    </div>

        <div
          id="formwrap"
          className={enableForm === 1 ? "formwrap active" : "formwrap"}
        >
          <form id="registration-form" onSubmit={handleFormSubmit}>
            <div className="text-center">
              <h1 className="heading">
                {t([currentLanguage==='in' ? "register-heading-left" : "Start Your" , "Start Your"])}{" "}
                <span className="red-text">
                  {t(["register-heading-right", "Journey"])}
                </span>
              </h1>
              <div className={step6 ? "step step-6 active " : "step step-6"}>
              {/* <div className={step1 ? "step step-1 active" : "step step-1"}> */}
                {/*  */}
                <ul className="progress-points">
                  <li className="active completed">
                    <div>01</div>
                  </li>
                  {/* <li className="">
                    <div>02</div>
                  </li>
                  <li className="">
                    <div>03</div>
                  </li>
                  <li>
                    <div>04</div>
                  </li>
                  <li>
                    <div>05</div>
                  </li> */}
                  <li className="active ">
                    <div>02</div>
                  </li>
                </ul>
                {/*  */}
                <div className="form-group">
                  <div className="label">
                    {" "}
                    {t(["profile-label", "Find the perfect match for"])}
                  </div>

                  <>
                    <select
                      id="field-profile-type"
                      name="profile_type"
                      onChange={(e) => handleProfileType(e.target.value)}
                      required
                    >
                      {profiletypes ? (
                        Object.entries(profiletypes).map(([id, type]) => (
                          <option key={"type-" + type.slug} value={type.slug}>
                            {type.slug && type.name
                              ? t([type.slug, type.name])
                              : type.name}
                          </option>
                        ))
                      ) : (
                        // Render an empty option if profiletypes is falsy
                        <option value="">
                          {t([
                            "no-profile-types",
                            "No profile types available",
                          ])}
                        </option>
                      )}
                    </select>
                  </>
                </div>
                {/*  */}

                <div className="form-group">
                  <div className="label"> {t(["name-label", "Your name"])}</div>
                  <div className="cols">
                    <input
                      type="text"
                      name="first_name"
                      className={`${(step1Error  && !dateError && name==="")  && 'errorBorder'}`}
                      placeholder={t(["first-name-placeholder", "First Name"])}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <input
                      type="text"
                      name="last_name"
                      placeholder={t(["last-name-placeholder", "Last Name"])}
                      className={`${(step1Error && !dateError && lastname==="")  && 'errorBorder'}`}
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                {/*  */}
                <div className="form-group">
                  <div className="label">
                    {t(["date-of-birth-label", "Date of Birth"])}
                  </div>
                  <div 
                  className={`cols dob-cols `}>
                    <select
                      id="birth_day"
                      name="date_of_birth_day"
                      onChange={(e) => handleDate(e.target.value)}
                      className={`${(step1Error && dateError && dayError)  && 'errorBorder'}`}
                    >
                      <option
                        key={"day-select-default"}
                        value={""}
                        disabled
                        selected
                      >
                        {t(["day", "Day"])}
                      </option>
                      {Object.entries(days).map(([id, day]) => (
                        <option key={"days-" + id} value={day.name}>
                          {day.name}
                        </option>
                      ))}
                    </select>
                    <select
                      className={`${(step1Error && dateError && monthError)  && 'errorBorder'}`}
                      id="birth_month"
                      name="date_of_birth_month"
                      onChange={(e) => handleMonth(e.target.value)}
                    >
                      <option
                        key={"month-select-default"}
                        value={""}
                        disabled
                        selected
                      >
                        {t(["month", "Month"])}
                      </option>
                      {Object.entries(month).map(([id, month]) => (
                        <option key={"month-" + id} value={month.name}>
                          {month.name}
                        </option>
                      ))}
                    </select>
                    <select
                      id="birth_year"
                      name="date_of_birth_year"
                      onChange={(e) => handleYear(e.target.value)}
                      className={`${(step1Error && dateError && yearError)  && 'errorBorder'}`}
                    >
                      <option
                        key={"day-select-default"}
                        value=""
                        disabled
                        selected
                      >
                        {t(["year", "Year"])}
                      </option>
                      {Object.entries(years).map(([id, year]) => (
                        <option key={"year-" + id} value={year.name}>
                          {year.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <div className="label">
                    {" "}
                    {t(["gender-label", "Your Gender"])}
                  </div>
                  <select
                    id="field-gender"
                    name="gender"
                    className={`${(step1Error && !dateError && selectedgender==="")  && 'errorBorder'}`}
                    // value={selectedgender} // Uncomment this if you want to control the value
                    onChange={(e) => handleGender(e.target.value)}
                    disabled={isRegistrationLoading}
                  >
                    {genders ? (
                      <>
                        <option
                          value=""
                          key="select-gender-default"
                          disabled={!isRegistrationLoading}
                          selected
                        >
                          {t(["select", "Select"])}
                        </option>
                        {Object.entries(genders).map(([id, gender]) => (
                          <option
                            key={"gender-" + gender.slug}
                            value={gender.slug}
                          >
                            {gender.slug && gender.name
                              ? t([gender.slug, gender.name])
                              : gender.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option value="">
                        {t(["no-genders", "No genders available"])}
                      </option>
                    )}
                  </select>
                </div>

                <div className="form-group">
                  <div className="label">
                    {t(["marital-status-label", "Marital Status"])}
                  </div>
                  <select
                    title="marital_status"
                    id="field-marital_status"
                    name="marital_status"
                    onChange={(e) => handleMarriageStatus(e.target.value)}
                    disabled={isRegistrationLoading}
                    className={`${(step1Error && !dateError && selectmarriagestatus==="")  && 'errorBorder'}`}
                  >
                    {marriagestatuses ? (
                      <>
                        <option
                          value=""
                          key="select-mariage-default"
                          selected
                          disabled
                        >
                          {t(["select", "Select"])}
                        </option>
                        {Object.entries(marriagestatuses).map(
                          ([id, status]) => (
                            <option
                              key={"marriage-staus-" + status.slug + "-" + id}
                              value={status.slug}
                            >
                              {status?.slug && status?.name
                                ? t([status?.slug, status?.name])
                                : status?.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                  <small>
                    {t([
                      "marital-notice",
                      "* Please note that Mohabbatein is exclusively for singles looking for matrimony",
                    ])}
                  </small>
                </div>
                {/*  */}

                {/*children  */}
                {displayChildren && (
                  <div className="form-group">
                    <div className="label">
                      {" "}
                      {t(["children-label", "Do you have kids?"])}
                    </div>
                    <select
                      id="field-children"
                      name="children"
                      // value={selectedgender} // Uncomment this if you want to control the value
                      onChange={(e) => handleChildren(e.target.value)}
                      disabled={isRegistrationLoading}
                    >
                      {children ? (
                        <>
                          <option
                            value=""
                            key="select-children-default"
                            disabled={!isRegistrationLoading}
                          >
                            {t(["select", "Select"])}
                          </option>
                          {Object.entries(children).map(([id, child]) => (
                            <option
                              key={"child-" + child.slug}
                              value={child.slug}
                            >
                              {child.slug && child.name
                                ? t([child.slug, child.name])
                                : child.name}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option value="">
                          {t(["no-genders", "No genders available"])}
                        </option>
                      )}
                    </select>
                  </div>
                )}





<label className="terms-label">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <span className="terms-checkbox"></span>

                    <div className="terms">
                      {t([
                        "terms-text",
                        "By creating account, you agree to our",
                      ])}{" "}
                      <div className="terms">
                        <a
                          href="https://mohabbatein.com/terms/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t(["terms-link", "Terms & Conditions"])}
                        </a>
                      </div>
                    </div>
                  </label>
                  <label className="terms-label">
                    <input
                      type="checkbox"
                      checked={isSmsChecked}
                      onChange={handleSMSCheckboxChange}
                    />
                    <span className="terms-checkbox"></span>

                    <div className="terms">
                      {t([
                        "terms-text-sms",
                        "You agree to receive otp from Mohabbatein for verification",
                      ])}{" "}
                    </div>
                  </label>


             

                {setmarriagestatuserror ? (
                  <div id="marriedAlert" className="alert alert-danger">
                    {t([
                      "marital-notice",
                      "* Please note that Mohabbatein is exclusively for singles looking for matrimony",
                    ])}
                  </div>
                ) : (
                  <>
                    
                  </>
                )}



{(!isChecked || !isSmsChecked) && termsmessage ? (
                  <>
                    <div className="alert alert-danger mobile-top-margin">
                      <span>
                        In order to proceed, please accept the terms by clicking
                        on the checkbox
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {submitError ? (
                  <div
                    className="alert alert-danger mobile-top-margin"
                    id="registration-error"
                  >
                    {t(submitErrorMessage)}
                  </div>
                ) : (
                  <></>
                )}

                {registrationError ? (
                  <div
                    className="alert alert-danger mobile-top-margin"
                    id="registration-error"
                  >
                    {errorOnRegistrationMessage}
                  </div>
                ) : (
                  <></>
                )}

                <div className="row navs">
                  <div className="col-12">
                    {/* <div
                      className="alert alert-danger"
                      id="registration-error"
                    ></div> */}
                    {/* <div className="mobile-only">
                      <div className="terms">
                        {t([
                          "terms-text",
                          "By creating account, you agree to our",
                        ])}{" "}
                        <a
                          href="https://mohabbatein.com/terms/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t(["terms-link", "Terms & Conditions"])}
                        </a>
                      </div>
                    </div> */}

                    <div className="d-flex w-100 justify-content-between">
                      <button
                        type="button"
                        className="nav-bt red-line float-start back"
                        onClick={handleStep2Back}
                      >
                        {t(["back", "Back"])}
                      </button>
                      {/* <div className="terms desktop-only">
                        {t([
                          "terms-text",
                          "By creating account, you agree to our",
                        ])}{" "}
                        <a
                          href="https://mohabbatein.com/terms/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t(["terms-link", "Terms & Conditions"])}
                        </a>
                      </div> */}

                      {!isChecked || !isSmsChecked ? (
                        <>
                          <Tooltip
                            id="select-terms-tooltip"
                            className="main-tooltips"
                            classNameArrow="custom-arrows"
                          />
                          <button
                            type="submit"
                            className="nav-bt red float-end bt"
                            data-tooltip-id="select-terms-tooltip"
                            data-tooltip-content="Please select Terms & Conditions"
                            onClick={handleTerms}
                          >
                            {t(["next", "Next"])}
                          </button>
                        </>
                      ) : (
                        <>
                          {isRegistrationLoading ? (
                            <button
                              className="nav-bt submit-loading float-end"
                              disabled
                              style={{ minWidth: "103px" }}
                            >
                              <img
                                src={sideloader}
                                alt={"loader"}
                                style={{
                                  height: "50px",
                                  marginRight: "-10px",
                                  marginTop: "-5px",
                                }}
                              />
                            </button>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="nav-bt red float-end bt"
                                {...(isRegistrationLoading ? " disabled" : "")}
                              >
                                {t(["next", "Next"])}
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>






                {/*  */}
              </div>

              <div className={step2 ? "step step-2 active" : "step step-2"}>
                {/*  */}
                <ul className="progress-points">
                  <li className="active completed">
                    <div>01</div>
                  </li>
                  <li className="active">
                    <div>02</div>
                  </li>
                  <li className="">
                    <div>03</div>
                  </li>
                  <li>
                    <div>04</div>
                  </li>
                  <li>
                    <div>05</div>
                  </li>
                  <li className="">
                    <div>06</div>
                  </li>
                </ul>
                {/*  */}

                {/*  Countries and City*/}

                <div className="form-group">
                  <div className="cols dob-cols">
                    <div className="">
                      <div className="label">
                        {t(["country-label", "Country"])}
                      </div>
                      <select
                        id="field-country"
                        name="country"
                        onChange={(e) =>
                          handleCountry(JSON.parse(e.target.value))
                        }
                        style={{ paddingRight: "45px" }}
                        className={`${(step2error   && selectedcountry==="")  && 'errorBorder'}`}
                      >
                        {countries ? (
                          <>
                            <option
                              key={"default-country"}
                              value=""
                              selected
                              disabled
                            >
                              {t(["select-country", "Select Country"])}
                            </option>{" "}
                            {Object.entries(countries).map(([id, country]) => (
                              // console.log(country),
                              <option
                                key={"country-" + country.slug}
                                value={JSON.stringify(country)}
                                // value={country.code + "-phone-" + country.phone}
                              >
                                {country.code && country.name
                                  ? t([country.code, country.name])
                                  : country.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>

                    <div className="">
                      <div className="label">{t(["city-label", "City"])}</div>
                      {cities ? (
                        <select
                          name="city"
                          id="field-cities"
                          className={`${
                            selectedcountry === "" ? "disabled" : ""
                          }   ${(step2error   && selectedcities==="")  && ' errorBorder'}`}
                     
                          onChange={(e) =>
                            handleCity(JSON.parse(e.target.value))
                          }
                          style={{ paddingRight: "45px" }}
                          disabled={selectedcountry === "" ? true : false}
                        >
                          {cities ? (
                            <>
                              <option
                                key={"default-city"}
                                value=""
                                selected
                                // disabled
                              >
                                {t(["select-city", "Select City"])}
                              </option>{" "}
                              {Object.entries(cities).map(([id, city]) => (
                                <option
                                  key={"city-" + city.slug + "-" + id}
                                  value={JSON.stringify(city)}
                                >
                                  {city?.slug && city?.name
                                    ? t([city?.slug, city?.name])
                                    : city?.name}
                                </option>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                {/*  */}
                <div className="form-group">
                  <div className="cols dob-cols">
                    <div className="">
                      <div className="label">
                        {t(["religion-label", "Your Religion"])}
                      </div>
                      <select
                        id="field-religion"
                        name="religion"
                        onChange={(e) =>
                          handleReligion(JSON.parse(e.target.value))
                        }
                        className={`${(step2error   && selectedereligion==="")  && 'errorBorder'}`}
                      >
                        {religions && (
                          <>
                            <option
                              key="default-religion"
                              value=""
                              selected
                              disabled
                            >
                              {t(["select-religion", "Select Religion"])}
                            </option>
                            {Object.entries(religions).map(([id, religion]) => (
                              <option
                                key={"religion-" + religion.slug}
                                value={JSON.stringify(religion)}
                              >
                                {religion.slug && religion.name
                                  ? t([religion.slug, religion.name])
                                  : religion.name}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    <div className="">
                      <div className="label">
                        {t(["your-community", "Your Community"])}
                      </div>
                      <select
                        id="field-caste"
                        name="caste"
                        onChange={(e) =>
                          handleCommunity(JSON.parse(e.target.value))
                        }
                        disabled={isRegistrationLoading}
                        className={`${(step2error   && selectedcommunity==="")  && 'errorBorder'}`}
                      >
                        {castes && (
                          <>
                            <option
                              key="default-community"
                              value=""
                              selected
                              disabled
                            >
                              {t(["select-community", "Select Community"])}
                            </option>
                            {Object.entries(castes).map(([id, caste]) => (
                              <option
                                key={"community-" + caste.slug + id}
                                value={JSON.stringify(caste)}
                              >
                                {caste?.slug && caste?.name
                                  ? t([caste?.slug, caste?.name])
                                  : caste?.name}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                {/* primary language */}

                <div className="form-group">
                  {/*  */}
                  <div className="cols dob-cols">
                    <div className="">
                      <div className="label">
                        {" "}
                        {t(["language-label", "Language"])}
                      </div>
                      <select
                        id="field-language"
                        name="language"
                        // value={selectedgender} // Uncomment this if you want to control the value
                        onChange={(e) => handleLanguage(e.target.value)}
                        disabled={isRegistrationLoading}
                        className={`${(step2error   && !selectLangauge)  && 'errorBorder'}`}
                      >
                        {primaryLanguage ? (
                          <>
                            <option
                              value=""
                              key="select-language-default"
                              disabled={!isRegistrationLoading}
                              selected
                            >
                              {t(["select", "Select"])}
                            </option>
                            {Object.entries(primaryLanguage)
                           
                            .map(
                              ([id, language]) => (
                                <option
                                  key={"language-" + language.slug}
                                  value={language.slug}
                                >
                                  {language.slug && language.name
                                    ? t([language.slug, language.name])
                                    : language.name}
                                </option>
                              )
                            )}
                          </>
                        ) : (
                          <option value="">
                            {t(["no-height", "No height available"])}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                {step2error ? (
                  <div className="alert alert-danger mobile-top-margin">
                    {t(step2errorMessage)}
                  </div>
                ) : (
                  <></>
                )}
                {step2errorImage ? (
                  <div className="alert alert-danger mobile-top-margin">
                    {step2errorImageMessage}
                  </div>
                ) : (
                  <></>
                )}

                <div className="row navs">
                  <div className="col-12">
                    <button
                      type="button"
                      className={`nav-bt red-line float-start back ${
                        iscrop ? "hidden" : ""
                      }`}
                      onClick={handleStep2Back}
                    >
                      {t(["back", "Back"])}
                    </button>
                    <button
                      type="button"
                      className={`nav-bt red float-end next `}
                      onClick={handleStep2Next}
                    >
                      {t(["next", "Next"])}
                    </button>
                  </div>
                </div>
              </div>

              <div className={step3 ? "step step-3 active" : "step step-3"}>
                {/*  */}
                <ul className="progress-points">
                  <li className="active completed">
                    <div>01</div>
                  </li>
                  <li className="active completed">
                    <div>02</div>
                  </li>
                  <li className=" active">
                    <div>03</div>
                  </li>
                  <li className="">
                    <div>04</div>
                  </li>
                  <li className="">
                    <div>05</div>
                  </li>
                  <li className="">
                    <div>06</div>
                  </li>
                </ul>

                {/* career and education */}

                <div className="form-group">
                  {/*  */}
                  <div className="cols dob-cols">
                    <div className="">
                      <div className="label">
                        {" "}
                        {t(["career-label", "Career"])}
                      </div>
                      <select
                        id="field-career"
                        name="height"
                        // value={selectedgender} // Uncomment this if you want to control the value
                        onChange={(e) => handleCareer(e.target.value)}
                        disabled={isRegistrationLoading}
                        className={`${(step3error   && !selectedcareer)  && 'errorBorder'}`}
                      >
                        {/* {console.log(career)} */}
                        {career ? (
                          <>
                            <option
                              value=""
                              key="select-career-default"
                              disabled={!isRegistrationLoading}
                              selected
                            >
                              {t(["select", "Select"])}
                            </option>
                            {Object.entries(career).map(([id, career]) => (
                              <option
                                key={"career-" + career.slug}
                                value={career.slug}
                              >
                                {career.slug && career.name
                                  ? t([career.slug, career.name])
                                  : career.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="">
                            {t(["no-height", "No height available"])}
                          </option>
                        )}
                      </select>
                    </div>

                    <div className="">
                      <div className="label">
                        {" "}
                        {t(["education-label", "Education"])}
                      </div>
                      <select
                        id="field-education"
                        name="education"
                        // value={selectedgender} // Uncomment this if you want to control the value
                        onChange={(e) => handleEducation(e.target.value)}
                        disabled={isRegistrationLoading}
                        className={`${(step3error   && !selectedEducation)  && 'errorBorder'}`}
                      >
                        {education ? (
                          <>
                            <option
                              value=""
                              key="select-education-default"
                              disabled={!isRegistrationLoading}
                              selected
                            >
                              {t(["select", "Select"])}
                            </option>
                            {Object.entries(education).map(
                              ([id, education]) => (
                                <option
                                  key={"education-" + education.slug}
                                  value={education.slug}
                                >
                                  {education.slug && education.name
                                    ? t([education.slug, education.name])
                                    : education.name}
                                </option>
                              )
                            )}
                          </>
                        ) : (
                          <option value="">
                            {t(["no-education", "No education available"])}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  {/*  */}
                  <div className="cols dob-cols">
                    <div className="">
                      <div className="label">
                        {" "}
                        {t(["height-label-2", "Height"])}
                      </div>
                      <select
                        id="field-height"
                        name="height"
                        // value={selectedgender} // Uncomment this if you want to control the value
                        onChange={(e) => handleHeight(e.target.value)}
                        disabled={isRegistrationLoading}
                        className={`${(step3error   && !selectedHeight)  && 'errorBorder'}`}
                      >
                        {heightObject ? (
                          <>
                            <option
                              value=""
                              key="select-height-default"
                              disabled={!isRegistrationLoading}
                              selected
                            >
                              {t(["select", "Select"])}
                            </option>
                            {Object.entries(heightObject)
                            .map(([id, height]) => (
                              <option
                                key={"height-" + height.slug + "-" + id}
                                value={height.slug}
                              >
                                {height.slug && height.name
                                  ? t([height.slug, height.name])
                                  : height.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="">
                            {t(["no-height", "No height available"])}
                          </option>
                        )}
                      </select>
                    </div>

                    <div className="">
                      <div className="label"> {t(["diet-label", "Diet"])}</div>
                      <select
                        id="field-diet"
                        name="diet"
                        // value={selectedgender} // Uncomment this if you want to control the value
                        onChange={(e) => handleDiet(e.target.value)}
                        disabled={isRegistrationLoading}
                        className={`${(step3error   && !selecteDiet)  && 'errorBorder'}`}
                      >
                        {diet ? (
                          <>
                            <option
                              value=""
                              key="select-diet-default"
                              disabled={!isRegistrationLoading}
                              selected
                            >
                              {t(["select", "Select"])}
                            </option>
                            {Object.entries(diet).map(([id, diet]) => (
                              <option
                                key={"diet-" + diet.slug}
                                value={diet.slug}
                              >
                                {diet.slug && diet.name
                                  ? t([diet.slug, diet.name])
                                  : diet.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="">
                            {t(["no-diet", "No diet available"])}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                {/* family typee and family value */}
                <div className="form-group">
                  {/*  */}
                  <div className="cols dob-cols">
                    <div className="">
                      <div className="label">
                        {" "}
                        {t(["family-type-label", "Family Type"])}
                      </div>
                      <select
                        id="family_type-height"
                        name="family_type"
                        // value={selectedgender} // Uncomment this if you want to control the value
                        onChange={(e) => handleFamilyType(e.target.value)}
                        disabled={isRegistrationLoading}
                        className={`${(step3error   && !selectfamilytype)  && 'errorBorder'}`}
                      >
                        {familytype ? (
                          <>
                            <option
                              value=""
                              key="select-family_type-default"
                              disabled={!isRegistrationLoading}
                              selected
                            >
                              {t(["select", "Select"])}
                            </option>
                            {Object.entries(familytype).map(([id, type]) => (
                              <option
                                key={"type-" + type.slug}
                                value={type.slug}
                              >
                                {type.slug && type.name
                                  ? t([type.slug, type.name])
                                  : type.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="">
                            {t(["no-family-type", "No family type available"])}
                          </option>
                        )}
                      </select>
                    </div>

                    <div className="">
                      <div className="label">
                        {" "}
                        {t(["family-value-label", "Family Value"])}
                      </div>
                      <select
                        id="field-family-value"
                        name="family-value"
                        // value={selectedgender} // Uncomment this if you want to control the value
                        onChange={(e) => handleFamilyValue(e.target.value)}
                        disabled={isRegistrationLoading}
                        className={`${(step3error && !selectFamilyValue)  && 'errorBorder'}`}
                      >
                        {familyValue ? (
                          <>
                            <option
                              value=""
                              key="select-family-value-default"
                              disabled={!isRegistrationLoading}
                              selected
                            >
                              {t(["select", "Select"])}
                            </option>
                            {Object.entries(familyValue).map(([id, value]) => (
                              <option
                                key={"family-value-" + value.slug}
                                value={value.slug}
                              >
                                {value.slug && value.name
                                  ? t([value.slug, value.name])
                                  : value.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="">
                            {t(["no-family-value", "No family available"])}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                {/*  */}

                {step3error ? (
                  <div className="alert alert-danger mobile-top-margin">
                    {t(step3errorMessage)}
                  </div>
                ) : (
                  <></>
                )}

                <div className="row navs">
                  <div className="col-12">
                    <button
                      type="button"
                      className="nav-bt red-line float-start back"
                      onClick={handleStep3Back}
                    >
                      {t(["back", "Back"])}
                    </button>
                    <button
                      type="button"
                      className="nav-bt red float-end next"
                      onClick={handleStep3Next}
                    >
                      {t(["next", "Next"])}
                    </button>
                  </div>
                </div>
              </div>

              <div className={step4 ? "step step-4 active " : "step step-4"}>
                {/*  */}
                <ul className="progress-points">
                  <li className="active completed">
                    <div>01</div>
                  </li>
                  <li className="active completed">
                    <div>02</div>
                  </li>
                  <li className=" active completed">
                    <div>03</div>
                  </li>
                  <li className="active">
                    <div>04</div>
                  </li>
                  <li className="">
                    <div>05</div>
                  </li>
                  <li className="">
                    <div>06</div>
                  </li>
                </ul>
                {/*  */}

                <div className="form-group">
                  <div className="label">Select Your Hobbies</div>
                  <div 
                  className={`autocomplete ${(step2errorImage && selectedHobbies?.length <=0  && (fileupload!=="" || blobImage!==""))  && 'errorBorderMain'}`}>
                    <>
                      {hobbies ? (
                        <>
                          {/* {console.log(hobbies)} */}
                          <AutocompleteSelect
                            options={hobbies}
                            onSelect={handleHobbies}
                            placeholder={t(["hobbies-placeholder", "Hobbies"])}
                            value={selectedHobbies}
                            // className={`profile-hobbies ${(step2errorImage && selectedHobbies?.length <=0 && fileupload!=="" && blobImage!=="" )  && 'errorBorder'}`}  
                          />
                          {}
                        </>
                      ) : (
                        <></>
                      )}
                    </>

                    {selectedHobbies?.length > 0 ? (
                      <>
                        {selectedHobbies.map((value, key) => (
                          <>
                            <span
                              key={"citizen-" + value?.slug}
                              value={value.slug}
                              className="autocompletefilter"
                              onClick={(e) => removeHobbies(e, key)}
                            >
                              {value?.slug && value?.name
                                ? t([value?.slug, value?.name])
                                : value?.name}
                            </span>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <div className="label">Upload your photo</div>
                  {!selectedImage && !selectedImageCropper && (
                    <>
                      <div className={`add-photo profile-photo`}>
                        <div id="addPhoto">
                          <input
                            type="file"
                            name="photo"
                            className={`form-control profile-photo`}
                            accept="image/*"
                            id="profile-photo"
                            onChange={uploadCropImage}
                          />

                          <button 
                          className={` ${(step2errorImage || fileupload==="" || blobImage==="")  && 'errorBorder'}`}
                          >
                            + {t(["add-photo", "Add a Photo"])}
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {/* {!isBrowser && (
                    <>
                      <div className={`add-photo profile-photo`}>
                        <div id="addPhoto">
                          <input
                            type="file"
                            name="photo"
                            className={`form-control profile-photo`}
                            accept="image/*"
                            id="profile-photo"
                            onChange={uploadImageDirect}
                          />

                          <button className="">
                            + {t(["add-photo", "Add a Photo"])}
                          </button>
                        </div>
                      </div>
                    </>
                  )} */}

                  {selectedImageCropper && isBrowser && (
                    <>
                      <Cropper
                        zoomable={false}
                        initialAspectRatio={1}
                        scaleX={1}
                        aspectRatio={1 / 1}
                        preview=".preview"
                        src={selectedImageCropper}
                        viewMode={1}
                        minCropBoxWidth={100}
                        checkOrientation={false}
                        guides={true}
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                      />
                      <button
                        type="button"
                        onClick={cropImage}
                        className="mt-3 nav-bt red float-end mb-2 back"
                      >
                        Crop
                      </button>

                      <div id="directPhotoWrapRegistration">
                        <input
                          type="file"
                          id="directPhoto"
                          accept=".jpg, .jpeg, .png"
                          max
                          onChange={uploadImageDirect}
                        />

                        {language === "en" ? (
                          <>
                            <p>
                              Having trouble uploading your photo?
                              <a href="#"> Click here</a>{" "}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              क्या आपको अपनी छवि काटने में परेशानी हो रही है?{" "}
                              <br />
                              <a href="#">
                                {" "}
                                अपनी फ़ोटो को मैन्युअल रूप से काटे बिना सीधे
                                अपलोड करने के लिए यहां क्लिक करें।
                              </a>{" "}
                            </p>
                          </>
                        )}
                      </div>
                      {/* <button className="cropper">Crop</button> */}
                    </>
                  )}

                  {selectedImageCropper && !isBrowser && (
                    <>
                      <ImageCropper
                        imgSrc={selectedImageCropper}
                        uploadImage={androidCropImage}
                        registrationCropper={true}
                      />
                    </>
                  )}

                  {selectedImageCropper && (
                    <>
                      {/* <Cropper
                        zoomable={false}
                        initialAspectRatio={1}
                        scaleX={1}
                        aspectRatio={1 / 1}
                        preview=".preview"
                        src={selectedImageCropper}
                        viewMode={1}
                        minCropBoxWidth={100}
                        checkOrientation={false}
                        guides={true}
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                      />
                      <button
                        type="button"
                        onClick={cropImage}
                        className="mt-3 nav-bt red float-end mb-2 back"
                      >
                        Crop
                      </button> */}
                      {/* 
                      <div id="directPhotoWrapRegistration">
                        <input
                          type="file"
                          id="directPhoto"
                          accept=".jpg, .jpeg, .png"
                          max
                          onChange={uploadImageDirect}
                        />

                        {language === "en" ? (
                          <>
                            <p>
                              Having trouble uploading your photo?
                              <a href="#"> Click here</a>{" "}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              क्या आपको अपनी छवि काटने में परेशानी हो रही है?{" "}
                              <br />
                              <a href="#">
                                {" "}
                                अपनी फ़ोटो को मैन्युअल रूप से काटे बिना सीधे
                                अपलोड करने के लिए यहां क्लिक करें।
                              </a>{" "}
                            </p>
                          </>
                        )}
                      </div> */}
                      {/* <button className="cropper">Crop</button> */}
                    </>
                  )}

                  {selectedImage && (
                    <>
                      <div className="photo">
                        <div className="remove-picture-button"
                        onClick={(e)=>removeImage(e)}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                      </div>
                        <img
                          src={selectedImage}
                          alt="Selected"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      </div>
                      {/* <div id="addPhoto">
                        <input
                          type="file"
                          name="photo"
                          className={`form-control profile-photo`}
                          accept="image/*"
                          id="profile-photo"
                          onChange={uploadImageDirect}
                        />

                        <button className="">Choose another photo</button>
                      </div>

                      <div id="directPhotoWrapRegistration">
                        <input
                          type="file"
                          id="directPhoto"
                          accept=".jpg, .jpeg, .png"
                          max
                          onChange={uploadImageDirect}
                        />

                        {language === "en" ? (
                          <>
                            <p>
                              Having trouble uploading your photo?
                              <a href="#"> Click here</a>{" "}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              क्या आपको अपनी छवि काटने में परेशानी हो रही है?{" "}
                              <br />
                              <a href="#">
                                {" "}
                                अपनी फ़ोटो को मैन्युअल रूप से काटे बिना सीधे
                                अपलोड करने के लिए यहां क्लिक करें।
                              </a>{" "}
                            </p>
                          </>
                        )}
                      </div> */}
                    </>
                  )}

                  <small id="photonote">
                    Please upload a clear photo of your face. Let's keep it real
                    – no animals or objects, just your wonderful self. Your
                    photo adds a personal touch, helping you connect
                    authentically.
                  </small>
                  <div></div>
                </div>

                {step2errorImage ? (
                  <div className="alert alert-danger mobile-top-margin">
                    {step2errorImageMessage}
                  </div>
                ) : (
                  <></>
                )}

                <div className="row navs">
                  <div className="col-12">
                    <div className="d-flex w-100 justify-content-between">
                      <button
                        type="button"
                        className={`nav-bt red-line float-start back ${
                          iscrop ? "hidden" : ""
                        }`}
                        onClick={handleStep4Back}
                      >
                        {t(["back", "Back"])}
                      </button>

                      <button
                        type="button"
                        className={`nav-bt red float-end next ${
                          iscrop ? "hidden" : ""
                        }`}
                        onClick={handleStep4Next}
                      >
                        {t(["next", "Next"])}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/*  */}

              <div
                className={step5 ? "step step-5 active " : "step step-5"}
              >
                {/*  */}
                <ul className="progress-points">
                  <li className="active completed">
                    <div>01</div>
                  </li>
                  <li className="active completed">
                    <div>02</div>
                  </li>
                  <li className=" active completed">
                    <div>03</div>
                  </li>
                  <li className="active completed">
                    <div>04</div>
                  </li>
                  <li className="active">
                    <div>05</div>
                  </li>
                  <li className="">
                    <div>06</div>
                  </li>
                </ul>
                {/*  */}

                <div className="form-group">
                  <div className="label">{t(["registration-bio-label", "About Me"])}</div>
                  <div className="autocomplete">
                    <textarea
                      name="email"
                      type="text"
                      value={about}
                      id="aboutMe"
                      rows="5"
                      onChange={(e) => setAbout(e.target.value)}
                      className={`${((step5Error   && aboutError) || (about==="" && step5Error))  && ' errorBorder'}`}
                      // className={`profile-hobbies ${(step2errorImage && selectedHobbies?.length <=0 && fileupload!=="" && blobImage!=="" )  && 'errorBorder'}`}  
                    ></textarea>
                  </div>
                </div>

                <div className="form-group">
                  <div className="label">{t(["registration-search-bio-label", "What I’m Looking For"])}</div>
                  <div className="autocomplete">
                    <textarea
                      name="email"
                      type="text"
                      value={lookingFor}
                      id="lookigFor"
                      rows="5"
                      onChange={(e)=>setLookingFor(e.target.value)}
                      className={`${((step5Error   && lookingForError) || (lookingFor==="" && step5Error))  && ' errorBorder'}`}
                    ></textarea>
                  </div>
                </div>

                {step5Error && (
                  <div className="alert alert-danger mobile-top-margin">
                    {step5Message}
                  </div>
                )}

                <div className="row navs">
                  <div className="col-12">
                    <div className="d-flex w-100 justify-content-between">
                      <button
                        type="button"
                        className={`nav-bt red-line float-start back ${
                          iscrop ? "hidden" : ""
                        }`}
                        onClick={handleStep5Back}
                      >
                        {t(["back", "Back"])}
                      </button>

                      <button
                        type="button"
                        className={`nav-bt red float-end next ${
                          iscrop ? "hidden" : ""
                        }`}
                        onClick={handleStep5Next}
                      >
                        {t(["next", "Next"])}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/*  */}
              <div className={step1 ? "step step-1 active" : "step step-1"}>
              {/* <div className={step6 ? "step step-6 active " : "step step-6"}> */}
                {/*  */}
                <ul className="progress-points">
                  <li className=" active">
                    <div>01</div>
                  </li>
                  {/* <li className="active completed">
                    <div>02</div>
                  </li>
                  <li className=" active completed">
                    <div>03</div>
                  </li>
                  <li className="active completed">
                    <div>04</div>
                  </li>
                  <li className="active completed">
                    <div>05</div>
                  </li> */}
                  <li >
                    <div>02</div>
                  </li>
                </ul>
                {/*  */}

                <div className="form-group">
                  <div className="label">
                    {t(["email-label", "Email Address"])}
                  </div>

                  <input
                    className={`form-control  ${(email==="" && sendError)  && 'errorBorder'}`}
                    type="email"
                    placeholder={t(["email-place-holder", "Email ID"])}
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleEmailChangeAndValidate}

                    required
                  />

{emailerror && <div style={{ color: "red" }}>{emailerror}</div>}
                   
                   { emailvalidationResult }

                  {language === "en" ? (
                    <>
                      <p className="lang">
                        Your email address will be used for verification.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="lang">
                        सत्यापन के लिए आपके ईमेल पते का उपयोग किया जाएगा
                      </p>
                    </>
                  )}
                </div>

                <div className="form-group">
                  <div className="label">
                    <span>
                      {t(["password-label", "Password"])}
                      <div className="tooltipy">
                        <div className="message">
                          <ul className="password-conditions-list">
                            <li>
                              <span className="password-conditions-list-item">
                                *
                              </span>
                              <span className="password-conditions-list-item-text">
                                {t(["error-password-space", ""])}{" "}
                              </span>
                            </li>
                            <li>
                              <span className="password-conditions-list-item">
                                *
                              </span>{" "}
                              <span className="password-conditions-list-item-text">
                                {t(["error-password-upper", ""])}{" "}
                              </span>
                            </li>
                            <li>
                              <span className="password-conditions-list-item">
                                *
                              </span>{" "}
                              <span className="password-conditions-list-item-text">
                                {t(["error-password-lower", ""])}{" "}
                              </span>
                            </li>
                            <li>
                              <span className="password-conditions-list-item">
                                *
                              </span>{" "}
                              <span className="password-conditions-list-item-text">
                                {t(["error-password-lenght", ""])}{" "}
                              </span>
                            </li>
                            <li>
                              <span className="password-conditions-list-item">
                                *
                              </span>{" "}
                              <span className="password-conditions-list-item-text">
                                {t(["error-password-digit", ""])}{" "}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder={t(["password-place-holder", "Password"])}
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={` ${(password==="" && sendError)  && 'errorBorder'}`}
                      required
                    />
                    <span
                      className={`password-toggle ${
                        showPassword ? "show-password" : "hide-password"
                      }`}
                      onClick={(e) => togglePasswordVisibility(e)}
                    >
                      {showPassword ? (
                        <img src={visibilityoff} alt="visibity off" />
                      ) : (
                        <img src={visibilityon} alt="visibity on" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="label">
                    {t(["mobile-number-label", "Mobile Number"])}
                  </div>
                  <div className="cols dob-cols">
                    {/* <div className="cols short-first"> */}
                    <div className="" name="country_code">
                      <select
                        name="country_code"
                        id="field-country-codes"
                        value={countrycode}
                        onChange={(e) => handleCountryCodes(e.target.value)}
                        onBlur={handlePhoneChangeAndValidate} 
                        style={{ paddingRight: "45px", width: "100%" }}
                      >
                        {mergedcountries ? (
                          // console.log(mergedcountries),
                          <>
                            <option
                              key={"default-country-code"}
                              value=""
                              selected
                              disabled
                            >
                              {t(["select-code", "Select Code"])}
                            </option>
                            {Object.entries(mergedcountries).map(
                              ([id, code]) => (
                                <option
                                  key={"country-code-" + id}
                                  value={code?.phone}
                                >
                                  {code?.code && code?.name
                                    ? t([code?.code, code?.name])
                                    : code?.name}{" "}
                                  - (+{code?.phone}){" "}
                                </option>
                              )
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>
                    <div className="mobile-top-margin" name="phone">
                      <input
                        type="text"
                        placeholder={t([
                          "mobile-number-label",
                          "Mobile Number",
                        ])}
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}  
                        onBlur={handlePhoneChangeAndValidate}
                        className={` ${(phone==="" && sendError)  && 'errorBorder'}`}
                        required
                      />

{phoneerror && <div style={{ color: "red" }}>{phoneerror}</div>}
                   
{ validationResult }
{/* {renderValidationStatus()} */}



                    </div>

                    {/*  */}
                  </div>

                 
                </div>

              {/* mk signup step 6 button  here  */}

              {step1Error ? (
  <>
	<div
	  id="marriedAlert"
	  className="alert alert-danger mobile-top-margin"
	>
	  {t(step1ErrorMessage)}
	</div>
  </>
) : (
  <></>
)}



<div className="row navs">
	  <div className="col-12 login-span">
		{/* <div>
		  <a href="/login-test/">
			{" "}
			{t(["account-exists", "I already have an account"])}
			{"  "}
		  </a>
		</div> */}

		<button
		  type="button"
		  className="nav-bt red float-end next"
		  onClick={handleStep1}
		>
		  {t(["next", "Next"])}
		</button>
	  </div>
	</div>

              </div>
            </div>
          </form>
        </div> 

        <div id="verification"
          className={enableForm === 2 ? "formwrap active" : "formwrap"}
        >
          <div className="text-center">

{/* mk custom code  */}





            <h2 className="heading">
              {t(["verify-header", "Verify Your Details"])}
            </h2>
            <ul className="progress-points">
              <li className="active completed">
                <div>01</div>
              </li>
              {/* <li className="active completed">
                <div>02</div>
              </li>
              <li className="active completed">
                <div>03</div>
              </li>
              <li className="active completed">
                <div>04</div>
              </li>
              <li className="active completed">
                <div>05</div>
              </li> */}
              <li className="active ">
                <div>02</div>
              </li>
            </ul>


{/* mk mobile code edit start here */}

<div className="form-group">
                  <div className="label">
                    {t(["mobile-number-label", "Mobile Number"])}
                  </div>
                  <div className="cols dob-cols">
         
                    <div className="" name="country_code">
                      <select
                        name="country_code"
                        id="field-country-codes"
                        value={countrycode}
                        onChange={(e) => handleCountryCodes(e.target.value)}
                        style={{ paddingRight: "45px", width: "100%" }}
                      >
                        {mergedcountries ? (
                          // console.log(mergedcountries),
                          <>
                            <option
                              key={"default-country-code"}
                              value=""
                              selected
                              disabled
                            >
                              {t(["select-code", "Select Code"])}
                            </option>
                            {Object.entries(mergedcountries).map(
                              ([id, code]) => (
                                <option
                                  key={"country-code-" + id}
                                  value={code?.phone}
                                >
                                  {code?.code && code?.name
                                    ? t([code?.code, code?.name])
                                    : code?.name}{" "}
                                  - (+{code?.phone}){" "}
                                </option>
                              )
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>
                    <div className="mobile-top-margin" name="phone">
                      <input
                        type="text"
                        placeholder={t([
                          "mobile-number-label",
                          "Mobile Number",
                        ])}
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className={` ${(phone==="" && sendError)  && 'errorBorder'}`}
                        required
                      />
                    </div>

                    {/*  */}
                  </div>

                </div>

{/* mk mobile code edit start end */}


            <h3 className="verify-subheader">
              {t([
                "verify-head",
                "We have sent you a verification code via text",
              ])}
            </h3>
            <p className="verify-subparagraph">
              {t([
                "verify-paragraph-1",
                "Enter the 5 digit verification code below:",
              ])}
            </p>

            <form id="otp-form" onSubmit={handleFormVerification}>
              <input
                type="hidden"
                name="user_id"
                id="verificationID"
                placeholder="USER ID"
              />
              <div className="inputs">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={5}
                  //   inputType={'text'}
                  inputStyle={"otpinput"}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus={true}
                />
              </div>

              {verificationError ? (
                <div style={{ paddingBottom: "29PX" }}>
                  <span className="error-text">{errorVerificationMessage}</span>
                </div>
              ) : (
                <></>
              )}

              <p className="terms text-start">
                {t(["verify-otp-notice-1", "Didn’t receive an OTP? Click"])}{" "}
                <a
                  href="/register"
                  onClick={(e) => {
                    handleVerifyUsingEmail(e);
                  }}
                >
                  {t(["verify-otp-notice-2", "here"])}
                </a>{" "}
                {t([
                  "verify-otp-notice-3",
                  "to verify your account via email.",
                ])}
              </p>

              <div className="verify-buttons">
                <div className="row">
                  <div className="col-12">
                    {/* <div
                    className="alert alert-danger"
                    id="verification-error"
                  ></div> */}

                    {isLoadingOtp ? (
                      <button
                        className="nav-bt red-outline float-start"
                        disabled
                        style={{ minWidth: "183px" }}
                      >
                        <img
                          src={sideloader}
                          alt={"loader"}
                          style={{
                            height: "50px",
                            marginRight: "-10px",
                            marginTop: "-5px",
                          }}
                        />
                      </button>
                    ) : (
                      <button
                        className="nav-bt float-start red-outline cus_mobile_btn"
                        onClick={(e) => handleResendOTP(e)}
                      >
                        {t(["resend-otp", "Resend OTP"])}
                      </button>
                    )}

                    {isRegistrationLoading ? (
                      <button
                        className="nav-bt submit-loading float-end"
                        disabled
                        style={{ minWidth: "128px" }}
                      >
                        <img
                          src={sideloader}
                          alt={"loader"}
                          style={{
                            height: "50px",
                            marginRight: "-10px",
                            marginTop: "-5px",
                          }}
                        />
                      </button>
                    ) : (
                      <>
                        <button className="nav-bt red float-end cus_mobile_btn">
                          {t(["submit", "Submit"])}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="otp-message">
                {optReset === 1 ? (
                  <>
                    <span className="success-otp">{optResetMessage}</span>
                  </>
                ) : (
                  <></>
                )}

                {optReset === 2 ? (
                  <>
                    <span className="error-otp">{optResetMessage}</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
        </div>

        <div
          id="email-verify"
          className={enableForm === 4 ? "formwrap active" : "formwrap"}
        >
          <div className="text-center">
            <h2 className="heading">
              {t(["verify-header", "Verify Your Details"])}
            </h2>
            <ul className="progress-points">
              <li className="active completed">
                <div>01</div>
              </li>
              {/* <li className="active completed ">
                <div>02</div>
              </li>
              <li className="active completed">
                <div>03</div>
              </li>
              <li className="active completed">
                <div>04</div>
              </li>
              <li className="active completed">
                <div>05</div>
              </li> */}
              <li className="active ">
                <div>02</div>
              </li>
            </ul>
            <br />

{/* email edit start here */} 
            
            <div className="form-group">
                  <div className="label">
                    {t(["email-label", "Email Address"])}
                  </div>

                  <input
                    className={`form-control  ${(email==="" && sendError)  && 'errorBorder'}`}
                    type="email"
                    placeholder={t(["email-place-holder", "Email ID"])}
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  {language === "en" ? (
                    <>
                      <p className="lang">
                        Your email address will be used for verification.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="lang">
                        सत्यापन के लिए आपके ईमेल पते का उपयोग किया जाएगा
                      </p>
                    </>
                  )}
                </div>

{/* email edit end*/}     
                
            <h3>
              {" "}
              {t([
                "verify-email-head",
                "We have sent you a verification link via email",
              ])}
            </h3>
            {/* <h3>{t(["verify-head","We have sent you a verification code via email"])}</h3> */}
            <br />
            <p>
              {t([
                "verify-email-paragraph",
                "Open the email we have sent you and click on the link to complete the verification process.",
              ])}
            </p>

            <p>
              {t([
                "registration-spam-folder",
                "Note: Please check your spam or junk mail folder as well.",
              ])}
            </p>

        


            {isLoadingEmail ? (
                      <button
                        className="nav-bt red-outline float-start"
                        disabled
                        style={{ minWidth: "183px" }}
                      >
                        <img
                          src={sideloader}
                          alt={"loader"}
                          style={{
                            height: "50px",
                            marginRight: "-10px",
                            marginTop: "-5px",
                          }}
                        />
                      </button>
                    ) : (
                      <div className="form-group">
                      <button
                      className="nav-bt float-start red-outline cus_mobile_btn"
                      onClick={(e) => handleEmailResendOTP(e)}
                      >
                      {t(["resend-verification-otp", "Resend Verification Code"])}
                      </button>     
                       </div>
          
                    )}


            <div className="otp-message">
                {emailReset == 1 ? (
                  <><br></br>
                    <p style={{ marginTop: '20px' }} ><span className="success-otp">  {emailResetMessage}</span></p>
                  </>
                ) : (
                  <></>
                )}

                {emailReset == 2 ? (
                  <><br></br>
                   <p  style={{ marginTop: '20px' }} > <span className="error-otp">{emailResetMessage}</span></p>
                  </>
                ) : (
                  <></>
                )}
              </div>


          </div>
        </div>
              <div id="registration-complete-tracking"></div>
        <div
          id="registration-complete"
          className={enableForm === 3 ? "formwrap active" : "formwrap"}
        >
          <div className="text-center">
            <h2 className="heading">
              {t(["all-set-1", "You’re"])}
              <span className="red-text">{t(["all-set-2", "all set!"])}</span>
              {/* You’re <span className="red-text">all set!</span> */}
            </h2>
            <img src={SuccessImage} alt="Mohabbatein Success" />
            <br />
            <p>
              {t([
                "discover-text",
                "Discover the magic of finding 'the one' as you begin your extraordinary love story with us.",
              ])}
            </p>
            <br />
            <a href="/login-test/">
              <button
                className="nav-bt red float-end"
                onClick={(e) => handleLogin(e)}
              >
                {t(["login-now", "Login Now"])}
              </button>
            </a>
          </div>
        </div>
 

      <div style={{ position: 'absolute', bottom: 0, left: 0 ,right:0 , textAlign: 'center'  }}>
      <img className="w-100-old img-fluid " src={ mainbottombanner } alt="" />
      </div>

        {/* <div className="right-top heart"></div>
        <div className="right-bot heart"></div> */}
      </section>

      {/* Footer */}

      {/* <AuthFooter /> */}
    </div>
  );
}