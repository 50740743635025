import React from 'react';
import { Link } from 'react-router-dom'
import { capitalizeWords } from "../../app/utilities/functions"
export default function Likers(data) {

    // Check if profile.likers is not null
    if (data?.data!== null) {
      // console.log(data?.data)
      console.log("get-likers "+JSON.stringify(data));
      return (
        <>
          {Object.entries(data?.data).map(([key, value]) => (
            // console.log(value?.photo),
            <div key={key} className="user">
              <div className={`user-photo  ${value?.is_premium ? 'premium' : ''}`}>
                {value?.name && <Link to={`/user/${value.slug}`}>
                <img src={value?.photo} alt={`User ${value.slug} ${key}`} />
                </Link>}
              </div>
              {value?.name && typeof value?.name !== "undefined" && <span>{capitalizeWords(value?.name)}</span>}
            </div>
          ))}
          </>
      );
    } else {
      // If profile.likers is null, hide the .likers-wrap
      return <div className="likers-wrap" style={{ display: 'none' }}></div>;
    }

}


